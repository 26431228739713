@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.modal {
  &.modal {
    width: 500px;
    padding: 20px;
  }
}

.container {
  display: flex;
  justify-content: space-between;

  .buttons {
    display: flex;
    gap: 15px;
  }

  .dateTime {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .errorMessage {
      @include input-error-message-styles;
    }

    .time {
      display: flex;
      align-items: center;

      .label {
        font-size: 15px;
        line-height: 18px;
        font-weight: normal;
        color: $color_grey_lighter;
        margin-right: 10px;
      }

      .doubleDot {
        margin: 0 3px;
      }

      .hours,
      .minutes {
        width: 55px;

        input {
          height: 35px;
          padding: 7px 10px;
        }
      }

      .utcInfo {
        display: flex;
        align-items: center;
        margin-left: 10px;
        color: $color_grey_lighter;
        font-size: 12px;
      }
    }
  }
}
