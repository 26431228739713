@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.label {
  display: block;
  position: relative;
  padding-left: 26px;
  user-select: none;
  word-break: break-word;

  color: $color_black;
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.error {
    animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.secondary {
    padding-left: 0;

    color: $color_border;
    font-weight: bold;
    transition: $transition_default;

    &.checked {
      color: $color_black;
    }

    &.disabled {
      opacity: 1;
    }
  }

  input {
    display: none;
  }

  &.sm {
    padding-left: 21px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color_white;
    border: $border_grey_light_thin;
    border-radius: $br_small;
    color: $color_white;
    font-size: 10px;
    line-height: 16px;
    transition: $transition_default;

    &.checked {
      background-color: $color_primary;
      border-color: $color_primary;
    }

    &.error {
      border: $border_warning_red;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
    }

    &.sm {
      height: 14px;
      width: 14px;
      font-size: 8px;
    }

    &.centered {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:hover {
    .checkmark {
      border: $border_grey;

      &.disabled {
        border: $border_grey_light_thin;
      }

      &.checked {
        border-color: $color_primary;
      }

      &.error {
        border: $border_warning_red;
      }
    }
  }
}

.errorMessage {
  @include input-error-message-styles;
}
