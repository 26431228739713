@import "scssShared/variables.scss";

.root {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: $color_primary;
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 1px;
  width: 100%;
  background: $color_grey_secondary_menu;
  border: 1px solid $color_border;
  border-radius: $br_medium;
  margin-bottom: 5px;

  .description {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin: 11px 0 9px;

    .not {
      margin-right: 3px;
      font-size: 12px;
    }

    .operator {
      color: $color_grey;
      vertical-align: middle;
      font-size: 8px;
    }
  }
}
