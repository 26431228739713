@import "scssShared/variables.scss";

.header {
  justify-content: space-between;
}

.headerButtonMargin {
  margin-right: 10px;
}

.actionButtonMargin {
  margin-left: 5px;
}

.tableActionsCol {
  width: 140px;
}

.tableEnabledCol {
  width: 220px;
}

.tableDashboardIdCol {
  width: 220px;
}
