@import "scssShared/baseVars.scss";
@import "scssShared/variables.scss";

.container {
  position: relative;
}

.button {
  display: flex;
  height: 48px;
  flex: 1;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  padding: 8px;

  .container.collapsed & {
    margin-left: -5px;
    padding: 5px;
  }
}

.logo {
  width: 32px;
  height: 32px;
  transition: all 0.1s ease;
  opacity: 1;

  .container.collapsed & {
    width: 24px;
    height: 24px;
  }
}

.title {
  margin-left: 5px;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  transition: all 0.1s ease;
  opacity: 1;

  .container.collapsed & {
    display: none;
    opacity: 0;
  }
}

.caret {
  transition: all 0.1s ease;
  margin-left: 5px;
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
  border-radius: 8px;
  background-color: white;
  width: 180px;
  z-index: 3;
  // border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: $border_grey_light_thin;
}

.topWrapper {
  background-color: $color_gray_50;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid $color_gray_200;
}

.user {
  background-color: $color-primary;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  max-width: 100%;

  .text {
    flex: 1;
    color: white;
    font-size: 11px;
    min-width: 0;
  }

  .email {
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .avatar {
    margin-left: 5px;
    width: 24px;
    height: 24px;
  }
}

.menu {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.menuItem {
  display: block;
  background-color: white;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: inherit;
  font-size: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  color: $color_black;
  transition: all 0.1s ease;

  &:hover {
    color: $color-primary;
  }
}

.menuItemIcon {
  font-size: 16px;
  margin-right: 10px;
  width: 20px !important; // TODO:
}

.bottomWrapper {
  background-color: $color_gray_50;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid $color_gray_200;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.bottomItem {
  background-color: white;
  border: solid 1px $color_gray_200;
  padding: 10px;
  color: $color_gray_500;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

.bottomIcon {
  margin-right: 5px;
}
