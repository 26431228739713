@import "scssShared/variables.scss";

.codesHeader {
  justify-content: space-between;
}

.content {
  margin-bottom: 20px;

  .actionButtonsColumn {
    width: 70px;
  }

  .availablePromoCodesColumn {
    width: 190px;
  }

  .dateModifiedColumn,
  .dateAddedColumn {
    width: 130px;
  }

  .createdByColumn {
    width: 220px;
  }

  .actionButtonMargin {
    margin-left: 5px;
  }

  .idColumn {
    width: 30px;
  }

  .copyButton {
    margin-left: -4px;
  }
}
