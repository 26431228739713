@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.segmentation-numbers-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .segmentation-label {
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    font-size: 10px;
    letter-spacing: 0.1em;
  }

  .box {
    font-weight: bold;
    color: $color_grey;
    display: flex;
    align-items: center;
    justify-content: center;

    .segmented {
      font-size: 14px;
      color: $color_black;
      min-width: 15px;
      text-align: center;
    }

    .out-of {
      font-size: 10px;
      margin: 0 5px;
      text-transform: uppercase;
    }

    .segmentation-total {
      font-size: 14px;
    }

    .na-tooltip {
      font-weight: normal;
      font-size: 11px;
    }
  }

  .segmented,
  .segmentation-total,
  .percents {
    &.loading {
      position: relative;
      cursor: wait;

      &:before {
        margin: -8px 0 0 -8px;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        content: "";
        border-radius: 24px;
        background-clip: padding-box;
        border-color: rgba($color_primary, 0.25);
        border-width: 2px;
        border-style: solid;
        border-top-color: $color_primary;
        animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      }

      span {
        color: transparent;
      }
    }
  }

  .percents {
    font-weight: 400;
    font-size: 14px;
    color: $color_grey;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  &.error {
    .box {
      .error-message {
        font-size: 12px;
        color: $color_warning_red;
        font-weight: 400;
      }
    }
  }
}
