@import "scssShared/variables.scss";

.customTable {
  width: 100%;
  border-collapse: collapse;
  display: table;

  .customThead {
    font-size: 11px;
    letter-spacing: 1px;
    color: $color_grey;
  }

  .customTh,
  .customTd {
    padding: 10px 8px;
    display: table-cell;
    vertical-align: middle;

    &.textAlignRight {
      text-align: right;
    }

    &.textAlignCenter {
      text-align: center;
    }

    &.overlap {
      box-sizing: border-box;
      width: 5px;
      padding: 0;
    }
  }

  .customTd {
    color: $color_grey_lighter;
    font-weight: 400;
    font-size: 14px;

    &.textBold {
      font-weight: bold;
    }

    &.textBlack {
      color: $color_black;
    }

    &.textBigger {
      font-size: 16px;
    }

    &.status {
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1px;
      font-weight: bold;
    }

    &.waiting {
      color: $color_yellow_waiting;
    }

    &.running {
      color: $color_blue_running;
    }

    &.finished {
      color: $color_green;
    }

    &.warning {
      color: $color_warning_orange;
    }

    &.error {
      color: $color_warning_red;
    }

    &.canceled {
      color: $color_border;
    }
  }

  .customTh {
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
  }

  .customThead {
    display: table-header-group;
  }

  .customTbody {
    display: table-row-group;
  }

  .customTr {
    display: table-row;
    text-decoration: none;
    text-transform: none;

    &:hover {
      .customTd {
        background: $table_row_hover;
      }

      &.linkRow {
        cursor: pointer;
        .customTd {
          background: $table_row_link_hover;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    &:focus {
      outline: none;
    }

    .customTd {
      transition: $transition_default;

      &:first-child {
        border-top-left-radius: $br_small;
        border-bottom-left-radius: $br_small;
      }

      &:last-child {
        border-top-right-radius: $br_small;
        border-bottom-right-radius: $br_small;
      }

      &:not(.overlap) {
        border-top: 1px solid #e6e6e6;
      }
    }

    &:first-of-type {
      .customTd {
        transition: none;
        border-top: 0;
      }
    }

    &.disabled {
      .customTd {
        opacity: 0.6;
      }
    }

    &.stickyTableHeader {
      .customTh {
        padding-top: 20px;
      }

      &.stickyOn {
        .customTh {
          position: sticky;
          top: 0;
          background: rgba($color_white, 0.8);
          z-index: 1;
          backdrop-filter: blur(8px);

          &:not(.overlap) {
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 100%;
              background: #e6e6e6;
            }
          }
        }
      }
    }
  }

  .cleaner {
    clear: both;
  }

  .withNew {
    .title {
      margin-right: 4px;
    }

    .newBadge {
      color: $color_primary;
      font-size: 8px;
      letter-spacing: 0.05em;
      border: 1px solid $color_primary;
      border-radius: $br_small;
      text-transform: uppercase;
      font-weight: bold;
      padding: 2px 4px 2px 6px;
      display: inline-block;
      width: 32px;
      height: 16px;
      box-sizing: border-box;
      position: relative;
      top: -2px;
    }
  }
}

.fullWidthRowMessage {
  color: $color_black;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 10px 8px;
}

.dragHandleColumn {
  width: 20px;
}

.dragHandleBtn {
  border: 0;
  background: transparent;
  padding: 0;

  > img {
    pointer-events: none;
  }

  &:focus {
    border: 0;
    outline: 0;
  }
}

.sortableActiveItem {
  width: 100%;
  background-color: $color_white;
  border-radius: $br_small;

  > div {
    width: 100%;
    display: table;
    background: $table_row_hover;
    border-radius: $br_small;
  }
}

.draggedItem {
  opacity: 0.3;
}

.sortButton {
  background: transparent;
  border: 0;
  font-size: 11px;
  letter-spacing: 1px;
  color: $color_grey;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  padding: 0;
  position: relative;
  padding-right: 9px;

  svg {
    position: absolute;
    right: 0;
    top: 0px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color_black;
  }

  &.active {
    color: $color_black;
  }
}
