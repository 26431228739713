// Colors
$color_primary: #fe7f66;
$color_primary_alternate: #ff6344;

$color_red: #ed382a;
$color_orange: #fbab62;
$color_green: #3aa545;
$color_yellow: #fabe53;
$color_blue: #229ace;

// Adapted from Tailwind 'neutral' palette
$color_gray_50: #fafafa;
$color_gray_100: #f5f5f5;
$color_gray_200: #e9e9e9;
$color_gray_300: #cbcbcb;
$color_gray_400: #a3a3a3;
$color_gray_500: #767676;
$color_gray_600: #535353;
$color_gray_700: #3a3a3a;
$color_gray_800: #262626;
$color_gray_900: #171717;
