@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.tagField {
  position: relative;

  &.error {
    input {
      @include input-error-animation;

      &:focus,
      &:hover {
        border: $border_warning_red;
      }
    }

    .errorMessage {
      @include input-error-message-styles;
    }
  }

  label {
    display: block;
    color: $color_grey;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  input,
  .helper {
    @include input-styles;

    background: transparent;
    font-weight: bold;
    border: 1px dashed $color_border;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color_black;
    box-sizing: border-box;
    padding: 7px 18px;

    &.hasValue,
    &:focus {
      border: $border_grey_light_thin;
      outline: none;
      box-shadow: none;
    }
  }

  .helper {
    position: absolute;
    visibility: hidden;
    white-space: pre;
  }

  &.primary {
    input {
      background: $color_primary;
      color: $color_white;
      border-color: $color_primary;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      input {
        background: $color;
        color: $color_white;
        border-color: $color;
      }
    }
  }
}
