@import "scssShared/variables.scss";

$modal-gap: 100px;
$modal-height: calc(100vh - 2 * #{$modal-gap});
$max-modal-height: 600px;
$factor: 1.6;

.galleryModal {
  width: unset !important;
  margin: auto;
  padding: 15px !important;

  .galleryModalContent {
    box-sizing: border-box;
    height: $modal-height;
    max-height: $max-modal-height;
    width: calc(#{$modal-height} * #{$factor});
    max-width: calc(#{$max-modal-height} * #{$factor});
    overflow: auto;
    padding: 5px;

    @media (max-height: 700px) {
      & {
        padding: 0;
        gap: 5px;
      }
    }
  }

  .templatesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .templateDetail {
    max-height: 100%;
    display: flex;
    gap: 20px;
    overflow: auto;

    .detailText {
      h2 {
        font-size: 18px;
        margin: 15px 0 10px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      p {
        margin: 10px 0;
        line-height: 1.4;
      }

      pre {
        padding: 5px 8px;
        background-color: #eee;
        font-size: 14px;
      }

      a {
        color: $color_primary;
        font-size: inherit;
        text-transform: inherit;
      }
    }

    .rightPanel {
      width: 400px;
      flex-shrink: 0;
    }

    .detailImg {
      width: 100%;
      border-radius: $br-large;
      border: $border_grey_light_thin;
      box-sizing: border-box;
    }

    .tagsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;
    }

    .tag {
      background-color: $color_grey;
      color: $color_white;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 700;
      font-size: 11px;
      border-radius: $br-large;
      padding: 6px 8px 6px 20px;
      position: relative;

      &::before {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color_white;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        content: " ";
      }
    }
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
  }
}
