@import "scssShared/variables.scss";

.container {
  display: flex;
  align-items: center;
  color: $color_grey;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0 20px;
  flex-wrap: wrap;

  & > *:not(:first-child) {
    margin-left: 5px;
  }
}

.symbol,
.operator,
.parens {
  margin-top: 4px;
  margin-bottom: 4px;

  &.greyedOut {
    opacity: 0.25;
    transition: opacity 0.1s ease;
  }
}

.symbol {
  padding: 0 8px;
  height: 34px;
  min-width: 34px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  font-size: 18px;
  cursor: default;
}

.negation {
  font-size: 11px;
  color: $color_white;
  background-color: $color_black_lighter;
  letter-spacing: 0.1em;
  padding: 4px;
  border-radius: 4px;
  margin-right: 4px;
}

.operator {
  height: 34px;
  display: flex;
  align-items: center;
}

.parens {
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 4px; // optically align
  height: 30px;
  line-height: 30px;

  & + & {
    margin-left: 0px;
  }
}

.duplicateButton {
  color: $color_white;
  cursor: pointer;
  line-height: 16px;
  font-size: 13px;
  font-weight: 700;
  text-decoration-line: underline;
}
