@import "scssShared/variables.scss";

.paperHeader {
  justify-content: space-between;
}

.frequencyCap {
  margin-top: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fieldsWrapper {
    display: flex;
    align-items: center;
    gap: 40px;

    .title {
      margin: 0;
      padding: 0;

      color: #222222;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }

    .fields {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 44px;

      .textInput {
        width: 70px;

        input {
          height: 44px;
          border-radius: $br_small 0 0 $br_small;
        }
      }

      .maxCount {
        display: flex;

        .times {
          box-sizing: border-box;
          height: 44px;
          width: 120px;
          display: flex;
          align-items: center;
          padding-left: 10px;

          background: #f7f9fb;
          border: 1px solid #cccccc;
          border-left: none;
          border-radius: 0 4px 4px 0;
          color: #777777;
          font-size: 15px;
          letter-spacing: -0.025em;
          line-height: 100%;
        }
      }

      .text {
        color: #222222;
        font-size: 16px;
        line-height: 19px;
        flex-shrink: 0;
      }

      .period {
        display: flex;

        .periodType {
          width: 120px;
        }
      }
    }
  }

  .tooltipWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 5px;
  padding: 10px;

  &.missingEmail {
    margin-top: 80px;
  }

  .loading {
    padding: 30px 0;
  }

  .addBatch {
    margin: 0 auto;
  }
}

.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_grey_lighter;
  height: 60px;
}

.historyWrapper {
  margin-top: 20px;
}
