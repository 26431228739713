@import "scssShared/variables.scss";

.icons-wrapper {
  position: relative;

  > label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    color: $color_grey;
    margin-bottom: 3px;
  }

  .error-message {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: $color_warning_red;
    position: absolute;
  }
}

.icons-options-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  label {
    display: block;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    transition: $transition_default;
    position: relative;

    .icon-container {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid $color_border;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color_white;

      img {
        width: 80%;
        height: auto;
      }
    }

    span {
      position: absolute;
      display: flex;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      font-size: 10px;
      text-align: center;
      color: $color_white;
      align-items: center;
      justify-content: center;
      background: $color_primary;
      bottom: -3px;
      right: -1px;

      .icon {
        display: block;
      }
    }
  }

  .icon-opt {
    display: none;

    &:checked + label {
      opacity: 1;
    }
  }

  &.active {
    .marked {
      opacity: 1;
    }
    label {
      opacity: 0.5;
    }
  }

  .icon-tooltip {
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
  }
}
