@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.modal-list {
  width: 720px !important;
  padding: 10px 5px !important;
  box-sizing: border-box;

  header {
    padding: 20px 20px 0;

    .close-button {
      top: 10px !important;
      right: 10px !important;
    }

    h3 {
      margin-bottom: 20px !important;
    }
  }

  .scrollable {
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0 20px 20px;
  }

  .select-field {
    margin-top: 10px;
    flex-grow: 1;
    margin-left: 56px;

    > label {
      display: none;
    }
  }

  button.invite-more-btn {
    padding: 0;
    margin: 0 0 0 56px;
    @include flexAcross();
    height: 20px;
    span {
      font-size: 12px;
      letter-spacing: 0.1em;
      font-weight: bold;
    }
  }

  .action-buttons {
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-body {
    .modal-list-form {
      position: relative;

      .send-emails {
        position: absolute;
        top: -38px;
        right: 50px;

        label {
          text-transform: none;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;
          letter-spacing: 0;
          font-size: 13px;
        }
      }

      .create-user-list {
        .form-row {
          padding-bottom: 15px;
          margin: 0 0 15px;
          border-bottom: 1px solid $color_border;

          .first-step-avatar {
            margin-right: 12px;
            width: 44px;
            height: 44px;
          }

          .trash-icon {
            margin-left: 5px;
          }

          &:last-of-type {
            border: none;
            margin: 0;
          }

          .email-field {
            width: 235px;
          }

          .password-field {
            margin-left: 10px;
          }

          .name-field {
            margin-left: 10px;
          }

          &.permissions {
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            border-bottom: 0;
            padding-bottom: 0;
          }

          .vr-line {
            width: 1px;
            background: $color_border;
            box-sizing: border-box;
            margin: 15px 0;
          }

          .permissions-user {
            text-align: center;
            margin: 0 15px;
            user-select: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 54px;
            cursor: pointer;
            .avatar-wrapper {
              margin: 0 auto;
              width: 48px;
              height: 48px;
              .inicials {
                font-size: 13px;
                line-height: 16px;
                font-weight: bold;
              }
            }
            .permissions-name {
              margin: 3px 0 0;
              align-self: center;
              font-weight: bold;
              color: $color_border;
              font-size: 11px;
              line-height: 13px;
            }

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }

            &.active {
              .permissions-name {
                color: $color_black;
              }
            }
          }

          .first-inner-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .name-pwd-wrapper {
            flex-basis: 360px;
            display: flex;
            justify-content: space-between;
          }

          .second-inner-row {
            display: flex;
            justify-content: space-between;

            .permissions-list {
              flex-basis: 330px;
              margin-right: 27px;
              margin-left: 10px;
              border-radius: $br_small;
              border: 1px solid $color_border;
              margin-top: 10px;
              padding: 10px;

              .label {
                font-weight: bold;
                font-size: 11px;
                text-transform: uppercase;
                line-height: 13px;
                letter-spacing: 0.1em;
                color: $color_grey;
                margin: 0 0 3px;
              }

              .no-role-selected {
                font-size: 15px;
                line-height: 18px;
                color: $color_black;
                opacity: 0.5;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .feature {
                margin-top: 5px;
                margin-right: 5px;
                background: $color_grey_secondary_menu;
                border: 1px solid $color_border;
                padding: 6px;
                border-radius: $br_small;
                font-weight: bold;
                font-size: 9px;
                line-height: 11px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: $color_grey_lighter;
                display: inline-block;
              }
            }
          }
        }

        .invite-more-btn {
          transition: none;
          &.hidden {
            visibility: hidden;
          }
        }
      }
      .pagination-lbl {
        letter-spacing: 1.95px;
        font-size: 13px;
      }
      .segments {
        border: 1px solid $color_border;
        padding: 0 10px;
        border-radius: 4px;
        max-height: 40vh;
        overflow: auto;
        background-color: $color_catskill-white;
        position: relative;

        .loading-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: wait;
          background: transparent;
        }

        .segment,
        .select-all {
          border-bottom: 1px solid $color_border;
          @include flexAcross();
          justify-content: space-between;
          align-items: center;

          &:last-of-type {
            border-bottom: none;
          }

          .checkbox-wrapper {
            margin: 10px 5px;
            display: flex;
            align-items: center;
            align-self: stretch;

            label {
              display: flex;
              align-items: center;
              margin-bottom: 0;
              padding-left: 14px;
              font-size: 13px;
              line-height: 16px;
              font-weight: normal;
              letter-spacing: 1px;
              text-transform: none;

              .horizontal-line {
                border-left: 1px solid $color_border;
                width: 1px;
                margin: 0 10px;
                align-self: stretch;
              }

              .bold-text {
                font-weight: bold;
                font-size: 11px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $color_grey;
                padding-top: 1px;
              }
            }
          }

          .fieldset {
            margin-bottom: 0;
          }

          .toggle-switch-wrapper {
            @include flexAcross();
            margin: 8px 5px 8px 10px;
            justify-content: flex-end;
            .form-label {
              display: none;
            }
            .toggle-switch {
              margin-top: 0;
            }
          }
        }

        .select-all {
          .checkbox-wrapper {
            margin: 8px 5px;
          }
        }
      }
    }
  }

  .warning-message {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
  }
}

.hide {
  display: none !important;
}
