@import "scssShared/mixins.scss";

.header {
  justify-content: space-between;
}

.buttonMargin {
  margin-right: 10px;
}

.formContent {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;

  .nameField {
    flex-basis: 25%;
  }

  .linkField {
    flex-basis: 45%;
  }

  .showToggleButtonWrapper {
    > label {
      @include input-label-styles;
      display: block;
    }
  }
}
