@import "scssShared/variables.scss";

.above-paper-right {
  position: absolute;
  top: -44px;
  right: 0;

  &.action-buttons {
    button,
    .version-history {
      float: left;
      margin-left: 10px;

      &.bigger-spacing {
        margin-left: 15px;
      }
    }
    .version-history {
      color: $color_black;
      font-size: 11px;
      letter-spacing: 1px;
      display: flex;
      height: 34px;
      align-items: center;
    }
  }

  p {
    float: left;
    margin: 11px 0 0;
    color: $color_grey;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.paper-header {
  box-sizing: border-box;
  min-height: 95px;
  width: 100%;
  border: 1px solid $color_border;
  background: $color_grey_secondary_menu;
  margin-bottom: -1px;
  border-radius: $br_medium;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

  &.small {
    min-height: 75px;
    padding: 10px 20px;
  }

  .sorting-form .select-field label,
  .segment-name-form label,
  .label {
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    color: $color_grey;
    display: block;
    text-transform: uppercase;
  }

  .label {
    margin: 0 0 8px;
  }

  .title {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: $color_grey;
    letter-spacing: 2px;
    margin: 0;
    line-height: 24px;

    .info-tooltip-icon {
      margin-left: 3px;
    }
  }

  .title-tooltip {
    font-weight: normal;
    width: 360px;
    font-size: 13px;
    line-height: 135%;
    color: $color_white;
    text-transform: none;
    padding: 10px;
    letter-spacing: normal;
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }

    a {
      font-size: 13px;
      text-transform: none;
      color: $color_white;
      font-weight: bold;
    }

    p {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .subtitle {
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    color: $color_grey;
    margin: 0;
  }

  .back-link {
    color: $color_grey;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 0;
    transition: $transition_default;

    border-right: $border_grey_light_thin;
    padding-right: 18px;
    margin-right: 20px;
    padding: 6px 18px 6px 12px;
    margin-left: -6px;

    &:hover {
      color: $color_black;
      box-shadow: none;
    }
  }
}
