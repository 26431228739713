@import "scssShared/variables.scss";

.button {
  margin: 0 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: all 0.1s ease;
  color: $color_grey_lighter;
  font-weight: 700;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid $color_primary;
    color: $color_black;
  }

  &.loading {
    cursor: wait;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
