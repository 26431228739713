@import "scssShared/variables.scss";

.chart {
  height: calc(100% - 62px);
  overflow-y: auto;
  padding-right: 13px;
  position: relative;

  &.notBottom {
    &::after {
      content: " ";
      position: absolute;
      bottom: 0em;
      width: 100%;
      height: 3em;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }

  .data {
    margin-top: 20px;

    .row {
      display: flex;
      align-items: center;
      margin: 10px 0;
      width: 100%;

      .label {
        flex-shrink: 0;
        padding-right: 5px;
        text-align: right;
        width: 50%;

        color: $color_grey_lighter;
        font-size: 11px;
        line-height: 13px;
        text-decoration: none;
        text-transform: none;
        transition: width 0.2s ease-in-out;
      }

      .bar {
        height: 22px;
        margin-left: 5px;
        position: relative;
        text-align: center;

        background: $color_primary;
        border-radius: 3px;
        color: $color_white;
        font-size: 11px;
        font-weight: bold;
        line-height: 23px;
        transition: width 0.2s ease-in-out;

        span {
          white-space: pre;

          &.nextTo {
            color: $color_primary;
            position: absolute;
          }
        }
      }
    }
  }

  &.expanded {
    .data {
      .row {
        .label {
          width: 20%;
        }
      }
    }
  }
}

.line {
  background: $color_very-light-grey;
  border: 0;
  height: 0.01em;
  margin-left: -2px;
  margin-right: -2px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 3px;
  margin-right: 3px;

  span {
    color: $color_grey_lighter;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.025em;
    line-height: 16px;
  }
}
