@import "scssShared/variables.scss";

.container {
  position: relative;
}

.tabs {
  position: absolute;
  top: 90px;
  height: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  color: $color_grey_lighter;
}

.tabButton {
  margin: 0 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: all 0.1s ease;
  color: $color_grey_lighter;
  font-weight: 700;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid $color_primary;
    color: $color_black;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.header {
  gap: 20px;

  .name {
    width: 300px;

    label {
      color: $color_grey;
      font-size: 13px;
      letter-spacing: 0.1em;
      line-height: 16px;
      margin: 0 10px 0 0;
    }

    input {
      border-radius: $br_medium;
    }
  }

  .buttons {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }
}

.body {
  display: flex;
  padding: 0;
  align-items: stretch;
  margin-top: 80px;
}

.formFields {
  padding: 20px;
  flex: 1;

  & > *:not(:first-child) {
    margin-top: 10px;
  }
}

.textFieldWrapper {
  position: relative;
}

.attributePickerWrapper {
  background-color: $color_catskill-white;
  position: absolute;
  box-sizing: border-box;
  border: $border_grey_light_thin;
  border-radius: 2px;
  right: 3px;
  bottom: 3px;
  height: 38px;
  width: 56px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: $color_grey_lighter;
  transition: color 0.1s ease, width 0.15s ease;
  padding: 10px;

  &:hover:not(.open) {
    color: $color_black_lighter;
  }

  &.inTextarea {
    // TODO: Figure out where the extra 4px bottom margin on the textarea comes from
    bottom: 7px;
  }

  &.open {
    width: 300px;
    padding-right: 0;
    z-index: 2;
  }

  .icons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bracketsIcon {
    font-size: 21px;
  }

  .ellipsisIcon {
    font-size: 13px;
    position: absolute;
  }

  .caretIcon {
    font-size: 13px;
  }

  .attributePicker {
    flex: 1;
    height: 34px;

    & > button {
      border-color: transparent;
      font-size: 13px;
    }

    & > textarea {
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.warning {
  margin-top: 5px;
  margin-left: 16px;
  font-size: 11px;
  font-weight: 700;
  color: $color_warning_orange;
}
