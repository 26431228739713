@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.root {
  display: inline-block;
  width: 100%;

  .wrapper {
    box-sizing: border-box;
    position: relative;

    &.row {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      label {
        margin: 0 10px 0 0;
      }
    }

    label {
      @include input-label-styles;
    }

    input {
      @include input-styles;

      box-sizing: border-box;
      width: 100%;
    }

    .password {
      position: relative;

      input {
        padding-right: 35px;
      }

      .icon {
        padding: 4px;
        position: absolute;
        right: 5px;
        bottom: 8px;
        cursor: pointer;

        svg {
          color: $color_border;
          transition: $transition_default;
        }

        &:hover {
          svg {
            color: $color_primary;
          }
        }
      }
    }
  }

  &.hasError {
    input {
      @include input-error-animation;

      &:hover {
        border-color: $color_warning_red;
      }

      &:focus {
        border-color: $color_warning_red;
        box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
      }
    }
  }
}
