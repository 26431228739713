@import "scssShared/variables.scss";

.user-form-screens {
  height: 100%;

  .form-wrapper,
  .caption {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .form-wrapper {
    background: linear-gradient(208deg, #fd8f79 0%, $color_primary 100%);
    width: 480px;
    float: left;
    flex-direction: column;

    .logo {
      max-width: 100%;
      width: 90px;
      height: 90px;
      display: block;
      margin-bottom: 20px;

      &.rotating {
        animation: spin 2s linear infinite;
      }
    }
  }
  .caption {
    background-color: $color_white;
    float: right;
    width: calc(100% - 480px);
    display: flex;
    justify-content: center;
    align-items: center;

    .content-wrapper {
      width: 620px;
      margin-left: 20px;
    }

    h1 {
      font-size: 48px;
      line-height: 58px;
      color: $color_primary;
      margin: 0;
    }

    h2 {
      margin: 0;
      font-size: 40px;
      font-weight: 500;
    }

    .updates {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      color: $color_grey;

      > span {
        color: $color_primary;
      }

      .release-notes-link {
        display: inline-block;
        height: 44px;
        box-sizing: border-box;
        box-shadow: 0 0 0 0 rgba($color_primary, 0.5);
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.15em;
        text-decoration: none;
        text-transform: uppercase;
        color: $color_grey_lighter;
        border: $border_grey_light_thin;
        border-radius: $br_small;
        padding: 13px 25px;
        transition: $transition_default;
        margin-right: 15px;
        animation: heartBeat 1.6s 3;
        background: $color_white;

        &:hover {
          color: $color_black;
          border-color: $color_grey;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
