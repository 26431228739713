@import "scssShared/variables.scss";

.edit {
  display: inline-block;
  min-width: 160px;
  padding-right: 10px;
  position: relative;
}

.view {
  height: 44px;
  display: flex;
  align-items: center;
}

.label {
  display: block;
  margin-bottom: 6px;

  color: $color_grey;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 13px;
  text-transform: uppercase;
}

.buttons {
  position: absolute;
  top: 11px;
  margin-right: -28px;
  right: 0;
  display: flex;
}

.button {
  background-color: $color_very-light-grey;
  color: $color_white;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 5px;
  flex-shrink: 0;
  font-size: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.05s ease;

  &:hover,
  &:focus {
    background-color: darken($color_very-light-grey, 5%);
  }
}

.saveButton {
  @extend .button;
  background-color: $color_primary;
  margin-left: -12px;
  z-index: 2;

  &:hover,
  &:focus {
    background-color: darken($color_primary, 5%);
  }
}
