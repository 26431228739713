@import "scssShared/variables.scss";

.authenticated-layout {
  height: 100vh;

  .content-wrap {
    margin-left: 200px;

    &.navCollapsible {
      margin-left: 60px;
    }
  }
}
