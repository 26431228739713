@import "scssShared/variables.scss";

$icon-btn-colors: (
  "grey": $color_grey,
  "red": $color_warning_red,
  "black": $color_black,
  "green": $color_green,
  "white": $color_white,
);

.iconButton {
  height: 28px;
  width: 28px;
  padding: 0;
  flex-shrink: 0;

  background: linear-gradient(
    to right,
    $color_primary_lighter 0%,
    $color_primary 50%,
    $color_primary_darker 100%
  );
  background-position: -1px 0%;
  background-size: 200%;
  border: 1px solid transparent;
  border-radius: $br_large;
  color: $color_white;
  cursor: pointer;
  font-size: 16px;
  transition: $transition_default;

  @each $name, $color in $icon-btn-colors {
    &.#{$name} {
      background: $color;
      border-color: $color;
      color: $color_white;
    }
  }

  &:focus {
    box-shadow: $button_white_focus_box_shadow;
    outline: none;
  }

  &:hover {
    background-position: 50% 0%;
    box-shadow: $bs_small;
  }

  &:disabled {
    background: $color_border;
    border-color: $color_border;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.xs {
    height: 24px;
    width: 24px;
    font-size: 12px;
  }

  &.md {
    height: 44px;
    width: 44px;
  }

  &.outlined {
    background: rgba($color_primary, 0.1);
    border-color: rgba($color_primary, 0.33);
    color: $color_primary;

    > svg {
      opacity: 0.75;
    }

    &:hover {
      background: rgba($color_primary, 0.15);
      border-color: rgba($color_primary, 0.5);

      > svg {
        opacity: 1;
      }
    }

    @each $name, $color in $icon-btn-colors {
      &.#{$name} {
        background: rgba($color, 0.1);
        border-color: rgba($color, 0.33);
        color: $color;

        &:hover {
          background: rgba($color, 0.15);
          border-color: rgba($color, 0.5);
        }
      }
    }

    &:disabled {
      background: rgba($color_very-light-grey, 0.1);
      border-color: rgba($color_very-light-grey, 0.33);
      color: $color_very-light-grey;
      opacity: 1;

      > svg {
        opacity: 0.6;
      }

      &:hover {
        background: rgba($color_very-light-grey, 0.1);
        border-color: rgba($color_very-light-grey, 0.33);

        > svg {
          opacity: 0.6;
        }
      }
    }
  }

  &.transparent {
    background: transparent;
    border: none;
    color: $color_primary;

    @each $name, $color in $icon-btn-colors {
      &.#{$name} {
        color: $color;
      }
    }

    &.md {
      font-size: 28px;
    }

    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      opacity: 0.5;
    }

    &:disabled {
      color: $color_grey;
      opacity: 0.5;
    }
  }
}

.disabledButtonWrapper {
  width: max-content;
}
