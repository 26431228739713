@import "scssShared/variables.scss";

.setup-sources {
  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .diagnostic-dashboard-tooltip {
    max-width: 320px;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    letter-spacing: 0;
    padding: 10px;
  }

  .icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $color_border;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_white;
    box-sizing: border-box;

    img {
      width: 80%;
      height: auto;
    }
  }

  .setup-sources-table {
    .icon {
      padding-left: 5px;
      padding-right: 10px;
      width: 32px;
    }

    .actions {
      .left-margin {
        margin-left: 5px;
      }
    }

    .name {
      width: 18%;
    }
    .diagnostic-dashboard-viz {
      width: 13.4%;
    }
    .engagement-viz {
      width: 11.9%;
    }
    .date-added {
      width: 8.5%;
    }

    .actions {
      width: 70px;
    }

    .description {
      white-space: pre-line;
    }

    .is-hidden {
      > div {
        opacity: 0.3;
      }
    }
  }
}
