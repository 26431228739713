@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.bannersBox {
  margin-top: 10px;
  margin-bottom: 10px;
}

.actionButtonMargin {
  margin-left: 5px;
}

.searchField {
  width: 315px;
}

.elementIdTooltip {
  word-break: break-all;
}

.actionsColumn {
  width: 100px;
}

.priorityColumn {
  width: 100px;
}

.toggleColumn {
  width: 100px;
}

.modifiedAtColumn {
  width: 190px;
}

.nameCell {
  word-break: break-word;
}

.modifiedAt,
.modifiedBy {
  display: block;
}

.modifiedBy {
  font-size: 11px;
}

.image {
  padding-top: 25px;
}

.editLink {
  text-transform: inherit;
}
