@import "scssShared/variables.scss";

:root {
  --tooltip-text-color: #ffffff;
  --tooltip-background-color: #959595;
  --tooltip-border-color: #7e7e7e;
  --tooltip-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  --tooltip-margin: 40px;
  --tooltip-arrow-size: 8px;
}

.tooltipWrapper {
  display: inline-block;
  position: relative;

  .tooltipTip {
    pointer-events: none;
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 10px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    z-index: 100;
    white-space: nowrap;
    letter-spacing: 0.05em;
    border: 1px solid var(--tooltip-border-color);
    bottom: calc(-1 * var(--tooltip-margin));
    text-transform: none;

    &:after {
      content: "";
      width: var(--tooltip-arrow-size);
      height: var(--tooltip-arrow-size);
      left: 50%;
      border: solid transparent;
      position: absolute;
      pointer-events: none;
      border: 1px solid var(--tooltip-border-color);
      margin-left: calc(-1 * (var(--tooltip-arrow-size) / 2));
      background: var(--tooltip-background-color);
      transform: rotate(45deg);
      bottom: calc(100% - (var(--tooltip-arrow-size) / 2));
      border-bottom: 0;
      border-right: 0;
    }
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
    button,
    a {
      pointer-events: none;
    }
  }
}

.delayedFadeEnter {
  opacity: 0.01;
}

.delayedFadeEnterActive {
  transition-property: opacity;
  transition-duration: 0.15s;
  transition-delay: 0.75s;
  transition-timing-function: ease-in-out;
  opacity: 1;
}

.delayedFadeExitActive {
  transition: opacity 0s;
  opacity: 0.01;
}

.delayedFadeExitDone {
  display: none;
}
