@import "scssShared/variables.scss";

.container {
  background-color: $color_catskill-white;
  width: 440px;
  border-top-right-radius: $br_medium;
  border-bottom-right-radius: $br_medium;
  border-left: $border_grey_light_thin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyMessage {
  color: $color_grey_lighter;
  font-size: 14px;
}

.imgWrapper {
  width: 100%;
  border-top: $border_grey_light_thin;
  border-bottom: $border_grey_light_thin;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & img {
    width: 420px;
  }
}

.notification {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 290px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  word-break: break-word;

  .header {
    display: flex;
    align-items: center;
  }

  .main {
    flex: 1;
  }

  &.iOS {
    background: rgba($color_white, 0.5);
    backdrop-filter: blur(8px);
    border-radius: 20px;
    color: $color_black;
    bottom: 130px;
    padding: 12px;
    padding-left: 10px;
    font-size: 13px;

    .appLogo {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      background-color: $color-white;
      border-radius: 10px;
      padding: 4px;
    }

    .time {
      font-size: 12px;
      color: rgba($color_black, 0.6);
      margin-left: 5px;
      white-space: nowrap;
    }

    .header {
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .titleText {
      font-weight: 700;
    }
  }

  &.android {
    background: rgba(45, 45, 47, 0.8);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    color: rgba($color_white, 0.8);
    top: 262px;
    padding: 12px 14px;
    font-size: 13px;

    .appLogo {
      width: 14px;
      height: 14px;
    }

    .appName {
      font-size: 13px;
      margin-left: 8px;

      &::after {
        content: "·";
        margin: 0 4px;
        font-weight: 700;
      }
    }

    .time {
      font-size: 12px;
      color: rgba($color_white, 0.6);
    }

    .titleText {
      margin: 6px 0 4px 0;
      font-weight: 700;
    }
  }
}

.infoText {
  padding: 20px;
  color: $color_grey_lighter;
  font-size: 14px;
}

.toggleSwitch {
  label {
    text-transform: none;
  }
}
