@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.container {
  &.xs {
    --size: 16px;
    --border-width: 2px;
  }
  &.sm {
    --size: 24px;
    --border-width: 2px;
  }
  &.md {
    --size: 36px;
    --border-width: 3px;
  }
  &.lg {
    --size: 48px;
    --border-width: 4px;
  }

  width: 100%;

  &.fixedWidth {
    width: var(--size);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.spinner {
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: solid var(--border-width) rgba($color_primary, 0.25);
  border-top-color: $color_primary;
  animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;

  .container.white & {
    border-color: rgba(white, 0.25);
    border-top-color: white;
  }
}
