@import "scssShared/variables.scss";

.toggle-button {
  padding: 2px;
  background: $color_green;
  box-shadow: $toggle_inset_box_shadow;
  position: relative;
  border: 0;

  .labels {
    font-size: 12px;
    color: $color_white;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    .offLabel {
      visibility: hidden;
    }
  }

  .switch {
    display: block;
    position: absolute;
    top: 3px;
    right: 3px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%), #ffffff;
    transition: all 0.5s;
    box-shadow: $box_shadow_toggle_active;
    transition: $transition_default;
  }

  &.off {
    background: $color_grey;
    .switch {
      color: $color_grey;
    }

    .labels {
      .offLabel {
        visibility: visible;
      }
      .onLabel {
        visibility: hidden;
      }
    }
  }

  &:focus {
    box-shadow: $toggle_inset_box_shadow;
    outline: none;
  }

  &.lg {
    width: 84px;
    height: 44px;
    border-radius: 22px;
    font-size: 15px;

    .switch {
      height: 34px;
      width: 34px;
      border-radius: 17px;
    }

    .labels {
      .onLabel {
        margin-left: 11px;
      }
      .offLabel {
        margin-right: 7px;
      }
    }

    .switch {
      top: 5px;
      right: 5px;
    }

    &.off {
      .switch {
        right: 44px;
      }
    }
  }

  &.md {
    width: 70px;
    height: 34px;
    border-radius: 20px;
    font-size: 15px;

    .switch {
      height: 28px;
      width: 28px;
      border-radius: 14px;
    }

    .labels {
      .onLabel {
        margin-left: 11px;
      }
      .offLabel {
        margin-right: 7px;
      }
    }

    &.off {
      .switch {
        right: 39px;
      }
    }
  }

  &.sm {
    width: 58px;
    height: 28px;
    border-radius: 14px;

    .switch {
      height: 22px;
      width: 22px;
      border-radius: 11px;
    }

    .labels {
      font-size: 11px;

      .onLabel {
        margin-left: 8px;
      }
      .offLabel {
        margin-right: 5px;
      }
    }

    &.off {
      .switch {
        right: 33px;
      }
    }
  }

  &.xs {
    width: 40px;
    height: 20px;
    border-radius: 10px;

    .switch {
      width: 14px;
      height: 14px;
      right: 3px;
      border-radius: 7px;
    }

    .labels {
      display: none;
      font-size: 8px;
      line-height: 8px;
      margin-top: 1px;

      .onLabel {
        margin-left: 4px;
      }
      .offLabel {
        margin-right: 2px;
      }
    }

    &.off {
      .switch {
        right: 23px;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  &:disabled:not(.toggling) {
    cursor: not-allowed;
  }

  &.toggling {
    cursor: wait;
  }
}
