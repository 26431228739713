@import "scssShared/variables.scss";

.customer-detail-header {
  &.show-system-message {
    margin-top: -20px;
  }

  .above-paper-right {
    position: static !important;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  .customer-detail-header-row2 {
    display: flex;
  }

  .basic-info {
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: calc(100% - 480px);

    .basic-info-title {
      font-size: 13px;
      margin: 20px 0 0 8px;
      text-transform: uppercase;
      color: $color_grey;
      letter-spacing: 1px;
      font-weight: bold;
    }

    .customer-title-avatar {
      display: flex;
      align-items: center;
      margin-top: -20px;
      margin-left: 5px;

      .customer-avatar {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        border-radius: $br_medium;
        border: $border_grey_light_thin;
        background-color: $color_bg_customer_avatar;
        box-sizing: border-box;
        padding: 12px;
      }

      h2 {
        color: $color_primary;
        margin: 0;
        font-size: 16px;
      }
    }

    .customer-attributes-values-list {
      font-size: 14px;

      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: $border_grey_white_bg;
        padding: 0 10px;
        overflow: hidden;

        &.compound-attr-view {
          display: block;

          .attrvalue {
            justify-content: flex-start;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        .attrname {
          flex-basis: 50%;
          max-width: 50%;
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: top;
          padding-right: 20px;
          box-sizing: border-box;

          .name-tags {
            display: flex;
            flex-wrap: wrap;
            min-height: 32px;
            padding-top: 4px;
            padding-bottom: 4px;
            align-items: center;
          }

          .name-wrapper {
            font-weight: bold;

            .name {
              margin-right: 4px;
            }
          }

          .tag {
            margin: 2.5px 5px 2.5px 0;
          }
        }
        .attrvalue {
          word-break: break-word;
          flex-basis: 50%;
          justify-content: flex-end;
          display: flex;
          align-items: center;
          text-align: right;
          padding: 10px 0;

          .value-wrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: flex-end;

            img {
              max-width: 60px;
              height: auto;
            }

            a {
              text-transform: inherit;
              letter-spacing: inherit;
              font-size: inherit;
            }
          }

          .show-more-attributes {
            margin-top: 10px;
            height: 24px;
            padding: 0 12px;
            font-size: 9px;

            &.loading {
              &:before {
                width: 14px;
                height: 14px;
                margin: -9px 0 0 -9px;
              }
            }
          }
        }
      }
    }
  }

  .chart-box {
    width: 470px;
    padding: 20px 0px;
    flex-shrink: 0;
    position: relative;

    h3 {
      font-size: 13px;
      color: $color_grey;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 20px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .info-icon {
      margin-right: 3px;
    }

    .info-tooltip {
      width: 320px;
    }

    .chart-wrapper {
      width: 440px;
      margin: 35px 10px 0;

      .chart-na {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin: 0;

        a {
          font-size: 14px;
          font-weight: bold;
          color: $color_primary;
          text-transform: none;
        }
      }
    }
  }
}
