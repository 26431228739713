@import "scssShared/variables.scss";

.details {
  display: -webkit-flex;
  -webkit-flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .timeline {
    width: calc(50% - 10px);

    .timelineHeader {
      .navigation {
        display: -webkit-flex;
        -webkit-flex-flow: row nowrap;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .back {
          box-sizing: border-box;
          flex-shrink: 0;
          height: 34px;
          padding: 6px 18px 6px 12px;
          margin-right: 20px;
          margin-left: -6px;

          color: $color_grey;
          cursor: pointer;
          background: transparent;
          border: none;
          border-right: $border_grey_light_thin;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: normal;
          text-transform: uppercase;
          transition: $transition_default;

          &:hover,
          &:focus {
            color: $color_black;
          }
        }

        .title {
          text-transform: uppercase;
          font-size: 13px;
          font-weight: bold;
          color: $color_grey;
          letter-spacing: 2px;
          margin: 0;
          line-height: 24px;
        }
      }
    }
    .timelineContent {
      .timelineTable {
        .timelineStatus {
          padding-left: 44px;
        }

        td {
          font-size: 14px;
          font-weight: normal;
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }
  .attributes {
    width: calc(50% - 10px);

    .noConditions {
      text-align: center;
      font-size: 14px;
      color: $color_grey_lighter;
      margin: 10px;
    }
  }
}
.exportLogs {
  margin-top: 20px;

  .logTable {
    table-layout: fixed;
    width: 100%;

    .logCell {
      white-space: pre-wrap;
      font-family: monospace;
      font-weight: normal;
      vertical-align: top;
      overflow-wrap: break-word;

      &.logStatus {
        font-size: 16px;
        line-height: 18px;
      }

      &.time {
        font-size: 14px;
        line-height: 16px;
      }

      &.message {
        font-size: 13px;
        line-height: 15px;
        overflow-x: hidden;

        a {
          font-size: 13px;
          font-weight: normal;
          color: $color_grey;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
      &.error {
        color: $color_warning_red;
      }
    }

    .logHead,
    .logCell {
      &.logStatus {
        width: 80px;
      }

      &.time {
        width: 180px;
      }
    }
  }
}
