@import "scssShared/variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 15px;
  width: 800px;
  height: 44px;

  background-color: $color_primary;
  border-radius: $br_small;
  color: $color_white;

  .textWrapper {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 5px;
      margin-right: 15px;
      height: 20px;
      width: 20px;
    }

    span {
      font-size: 14px;
      line-height: 17px;
    }
  }

  button {
    background-color: inherit;
    border: 1px solid $color_white;
    border-radius: $br_small;
    box-sizing: border-box;
    color: $color_white;
    font-size: 11px;
    font-weight: bold;
    height: 34px;
    letter-spacing: 0.15em;
    line-height: 13px;
    padding: 10px 6px;
    text-transform: uppercase;

    &:hover {
      border-color: rgba(255, 255, 255, 0.75);
    }

    &:active,
    &:focus {
      border-color: rgba(255, 255, 255, 0.75);
    }
  }
}
