@import "scssShared/variables.scss";

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #999999;
      border: 1px solid #cccccc;
      border-radius: $br_large;
    }

    .title {
      color: #222222;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
    }
  }

  .outOf {
    color: #999999;
    font-size: 14px;
    line-height: 17px;

    b {
      color: #222222;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
