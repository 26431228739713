@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.global-settings {
  .cancel-button {
    margin-right: 10px;
  }

  .global-settings-content {
    padding: 0;
    min-width: 1140px;

    .global-settings-table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 5px;

      td {
        padding: 30px 20px;

        &.row-title {
          padding: 30px 0px 30px 20px;
          width: 280px;

          @media screen and (max-width: 1099px) {
            width: 220px;
          }
        }

        &.values {
          &:after {
            content: "";
            display: table;
            clear: both;
          }

          .additional-attribute {
            z-index: 4;
          }

          .contact-identifiers {
            z-index: 3;
          }

          .channel-engagement-value {
            z-index: 2;
          }
        }
      }

      tr {
        border-bottom: 1px solid $color_border;

        &:last-child {
          border-bottom: 0;

          td {
            border-radius: 5px;
          }
        }

        &:nth-child(2n) {
          td {
            background-color: $color_grey_secondary_menu;
          }
        }
      }
    }

    .row-title {
      h3 {
        margin: 0;
      }

      .title-description {
        color: $color_grey;
        margin: 5px 0 0;
        font-size: 14px;

        a {
          text-transform: none;
          color: $color_primary;
          font-size: 14px;
        }

        span {
          color: $color_black;
        }
      }
    }

    .form-row {
      float: right;
      @include flexAcross();
      align-items: center;

      &.maintenance-emails {
        width: 680px;
      }

      .notification-email-wrapper {
        display: flex;
        align-items: center;

        .email-trash {
          margin-left: 4px;
        }
      }

      .add-email-button {
        margin-left: 15px;
      }

      .email-fields {
        .notification-email-wrapper {
          margin-bottom: 20px;

          .error-message {
            position: absolute;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .pivoted-generating-switch {
        margin-right: 560px;
      }

      .text-field,
      .select-field,
      .picker-field {
        position: relative;
        width: 280px;

        @media screen and (max-width: 1199px) {
          width: 250px;
        }

        label {
          position: absolute;
          top: 15px;
          right: 295px;
          letter-spacing: 1.5px;

          @media screen and (max-width: 1199px) {
            right: 265px;
          }
        }

        input {
          width: 100%;
          box-sizing: border-box;
        }

        &.host-url {
          float: left;
          width: 430px;

          label {
            right: 445px;
          }

          @media screen and (max-width: 1199px) {
            width: 390px;

            label {
              right: 405px;
            }
          }
        }

        &.driver {
          float: left;
          margin-left: 73px;
          width: 170px;

          &.bigquery {
            margin-left: 230px;
          }

          label {
            right: 185px;
          }

          @media screen and (max-width: 1199px) {
            width: 140px;

            &.bigquery {
              margin-left: 260px;
            }

            label {
              right: 155px;
            }
          }
        }

        &.port {
          float: left;
          margin-left: 67px;
          width: 90px;

          label {
            right: 105px;
          }

          @media screen and (max-width: 1199px) {
            margin-left: 80px;
          }
        }
      }

      &.cache-settings {
        position: relative;
        .cache-settings-label {
          margin-right: 5px;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 10px;
          line-height: normal;
        }
      }

      .expiration-schedule-field {
        display: inline-block;
        width: 82px;
        position: relative;
        margin-left: 10px;

        .error-message {
          display: none;
        }

        label {
          position: absolute;
          color: $color_grey;
          font-size: 9px;
          font-weight: bold;
          letter-spacing: 0.15em;
          display: block;
          text-align: center;
          bottom: -15px;
          left: 0;
          right: auto;
          top: auto;
          margin: 0;
          width: 100%;
        }
      }

      .floated {
        float: left;

        &.margin-left {
          margin-left: 100px;
          width: 300px;

          label {
            right: 315px;
          }

          @media screen and (max-width: 1199px) {
            margin-left: 150px;
          }
        }

        &.exports-db-name {
          width: 250px;
          margin-left: 150px;

          label {
            right: 269px;
          }
        }

        &.demo-db-name {
          width: 270px;
          margin-left: 130px;

          label {
            right: 291px;
          }
        }

        &.single {
          margin-right: 400px;

          @media screen and (max-width: 1199px) {
            margin-right: 400px;
          }
        }

        &.exports-dataset,
        &.demo-dataset {
          width: 250px;
          margin-left: 150px;

          label {
            right: 265px;
          }
        }

        &.meiro-api-url {
          width: 680px;

          label {
            right: 695px;
          }
        }
      }

      .cache-db-port {
        float: left;
        margin-left: 67px;
        width: 90px;

        label {
          right: 105px;
        }
      }

      .select-field {
        &.schema {
          float: left;
          margin-left: 83px;
          width: 160px;

          label {
            right: 175px;
          }
        }
      }

      .bigquery-credentials {
        width: 680px;

        label {
          right: 695px;
        }

        @media screen and (max-width: 1199px) {
          width: 650px;

          label {
            right: 665px;
          }
        }
      }

      .toggle {
        margin-top: 5px;

        .toggle-switch {
          .fieldset {
            margin: 0;
          }
        }
      }

      .select-field {
        .css-11unzgr {
          max-height: 145px;
        }
      }

      .delete-cache {
        width: 215px;
        margin-left: 15px;
        font-size: 13px;
        padding-left: 2px;
        padding-right: 2px;

        &.loading {
          &:before {
            width: 14px;
            height: 14px;
            margin: -16px 0 0 -9px;
          }
        }

        .small-text {
          color: $color_white;
          font-size: 8px;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          display: block;
          margin-top: 21px;

          > .duration {
            color: $color_white;
            text-transform: none;
          }
        }
      }

      .attribute-picker {
        label {
          letter-spacing: 1.5px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 10px;
          line-height: normal;
        }

        .filter-attribute-select-wrapper {
          width: 280px;

          .ghost-field {
            min-height: 44px;
          }

          .filter-attribute-select-input,
          .ghost-field {
            padding-top: 12px;
            padding-bottom: 11px;
          }
        }
      }

      &.cdp-cache-toggle {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        flex-shrink: 0;

        .toggle-switch {
          margin-right: 560px;

          .fieldset {
            margin-bottom: 0;
          }
        }
      }
    }

    td {
      .form-row {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.settings-text-field {
  width: 280px;

  label {
    position: absolute;
    top: 15px;
    right: 295px;
    letter-spacing: 1.5px !important;
  }

  &.db-name {
    width: 680px;

    label {
      right: 695px;
    }
  }
}
