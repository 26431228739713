@import "scssShared/variables.scss";

.note-form {
  .note-form-header {
    margin: 10px 0 15px;

    &:after {
      display: table;
      content: "";
      clear: both;
    }

    .back-button {
      float: left;
      margin: 29px 0 0 11px;
      padding: 4px;
      min-height: 0;
      min-width: 0;
      width: 20px;
    }
    .note-name {
      width: 85%;
      float: right;
      margin-right: 15px;

      input {
        width: 100%;
        box-sizing: border-box;
      }

      label {
        display: block;
        color: $color_grey;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 12px;
        font-size: 10px;
      }
    }
  }

  .note-markdown-textarea {
    padding: 0 15px;
    height: 214px;

    textarea {
      background: $color_grey_site_background;
      border-radius: 0;
      border: none;
      font-size: 14px;
      line-height: 22px;
      padding: 10px;
      height: 214px;

      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
  }

  .markdown-content-wrapper {
    box-sizing: border-box;
    height: 214px;
    padding: 0 15px;
    width: 100%;
    font-size: 14px;
    line-height: 22px;

    .markdown-content {
      box-sizing: border-box;
      background: $color_grey_secondary_menu;
      padding: 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      white-space: pre-line;
      overflow: auto;

      &.disabled {
        cursor: not-allowed;
      }

      p {
        margin: 0 0 8px;
      }

      h1 {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 10px;
      }

      h2 {
        font-size: 21px;
        line-height: 24px;
        margin: 0 0 8px;
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 8px;
      }
    }
  }

  .note-actions {
    height: 60px;
    background: $color_grey_secondary_menu;
    border-top: $border_grey_light_thin;
    box-sizing: border-box;
    text-align: center;
    padding-top: 13px;

    button {
      &:first-child {
        margin: 0 10px 0 0;
      }
    }
  }

  .tips {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    padding: 12px 15px;

    span {
      margin: 0 4px;

      &.headline {
        margin-left: 8px;
      }

      &.italics {
        font-weight: normal;
        font-style: italic;
      }

      &.strikethrough {
        font-weight: normal;
        text-decoration: line-through;
      }

      &.link-tip {
        font-weight: normal;
      }
    }
  }
}
