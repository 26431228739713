@import "scssShared/variables.scss";

.create-source {
  .header-buttons {
    > * {
      margin-left: 10px;
    }
  }

  .create-source-content {
    padding: 0;
  }
}
