@import "scssShared/variables";

.chatModal {
  width: 380px;
  box-sizing: border-box;
  padding: 20px 10px;
  background-color: $color_white;
  position: fixed;
  top: 80px;
  bottom: 80px;
  z-index: 2147483647;
  right: 10px;
  border-radius: $br_large;
  box-shadow: $bs_smallest;
  background-image: url("./topBg.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 90px;
  border: 1px solid $color_gray_300;
}

.meiroLogo {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 2.5px;
  margin-right: 5px;
}

.header {
  display: flex;

  h3 {
    margin: 0;
    font-size: 21px;
  }

  .closeButton {
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 12px;
    color: $color_gray_500 !important;
  }
}

.subHeader {
  margin: 8px 10px;
  font-size: 14px;
  letter-spacing: -0.015em;
  color: $color_gray_500;
}

.form {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;

  .formFieldWrapper {
    position: relative;
  }

  .formField {
    input {
      padding-right: 45px;
    }
  }

  .submitButton {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .loadingIndicator {
    position: absolute;
    top: 10px;
    right: 14px;
  }
}

.content {
  margin: 10px 0;
  height: calc(100% - 145px);
  position: relative;

  &.scrolledToTop {
    &:after {
      position: absolute;
      height: 40px;
      bottom: 0;
      content: "";
      display: block;
      background: linear-gradient(to top, white calc(100% - 40px), transparent 100%);
      width: 100%;
    }
  }

  &.scrolledToMiddle {
    &:after {
      position: absolute;
      height: 40px;
      bottom: 0;
      content: "";
      display: block;
      background: linear-gradient(to top, white calc(100% - 40px), transparent 100%);
      width: 100%;
    }

    &:before {
      position: absolute;
      height: 40px;
      top: 0;
      content: "";
      display: block;
      background: linear-gradient(to bottom, white calc(100% - 40px), transparent 100%);
      width: 100%;
      z-index: 1;
    }
  }

  &.scrolledToBottom {
    &:before {
      position: absolute;
      height: 40px;
      top: 0;
      content: "";
      display: block;
      background: linear-gradient(to bottom, white calc(100% - 40px), transparent 100%);
      width: 100%;
      z-index: 1;
    }
  }

  :global {
    .simplebar-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 100%;
    }
  }

  .queryBox {
    display: flex;
    padding: 0 10px 0 8px;

    .avatar {
      width: 28px;
      height: 28px;
      margin-right: 6px;
      flex-shrink: 0;

      > img {
        border-radius: $br_large;
      }
    }

    p {
      margin: 2px 0 0;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: -0.015em;
      color: $color_black;
    }
  }

  .responseBox {
    margin-top: 15px;
    display: flex;
    padding: 10px;
    background: $color_grey_site_background;
    border-radius: $br_large;

    .aiAvatar {
      width: 24px;
      height: 24px;
      border-radius: $br_large;
      flex-shrink: 0;
      margin-right: 8px;
      border: 1px solid $color_primary;
      font-size: 16px;
      color: $color_primary;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(254, 127, 102, 0.1), rgba(254, 127, 102, 0.1)), #ffffff;
    }

    p {
      margin: 2px 0 0;
      font-size: 14px;
      letter-spacing: -0.015em;
      color: $color_black;
    }

    .error {
      color: $color_red;
    }
  }
}
