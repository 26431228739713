@import "scssShared/variables.scss";
@import "scssShared/optionGroupColors.scss";

@import "./colorMixins.scss";

@mixin insight-color {
  @each $name, $color in $option-group-colors {
    &.#{$name} {
      color: $color;
    }
  }
}

@mixin insight-background-color {
  @each $name, $color in $option-group-colors {
    &.#{$name} {
      background: $color;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 720px !important;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    .titleWrapper {
      display: flex;
      align-items: center;

      > * {
        margin-left: 5px;
      }

      img {
        height: 24px;
        width: 24px;
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.025em;
        line-height: 17px;

        @include insight-color;
      }

      .tooltip {
        color: $color_grey;
        font-size: 12px;
      }

      .new {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 30px;
        margin-left: 6px;

        color: $color_primary;
        border: 1px solid $color_primary;
        border-radius: $br_small;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }
    }

    .buttons {
      display: flex;
      align-items: center;

      & > * {
        margin-left: 5px;
      }

      .iconButton {
        height: 34px;
        width: 34px;
        padding: 4px;

        font-weight: 900;
        line-height: 16px;

        @each $name, $color in $option-group-colors {
          &.#{$name} {
            background: rgba($color, 15%);
            border: 1px solid $color;
            color: $color;
          }
        }
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    padding: 20px;

    > * {
      margin-bottom: 5px;
    }
  }

  .content {
    padding: 20px;
    background: #f7f9fb;
    border-radius: 0 0 4px 4px;

    .values {
      display: flex;
      justify-content: space-evenly;
      padding-top: 21px;
      padding-bottom: 28px;

      letter-spacing: -0.025em;

      &.column {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .type {
        font-size: 24px;
        line-height: 30px;
      }

      .value {
        @include insight-color;

        font-size: 40px;
        line-height: 48px;
      }

      .errorMessage {
        color: $color_black;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.025em;
        line-height: 31px;
      }
    }

    .charts {
      .label {
        color: $color_black;
        font-size: 14px;
        letter-spacing: -0.025em;
        line-height: 17px;
      }

      .flexBox {
        display: flex;
        flex-direction: column;
        padding-top: 8px;

        & > *:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      hr {
        color: $color_very-light-grey;
        margin: 0 0 15px 0;
      }
    }

    .chart {
      padding-bottom: 24px;
    }
  }
}
