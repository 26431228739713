.actionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.primaryActionButton,
.secondaryActionButton {
  width: 275px;
}
.secondaryActionButton {
  margin-bottom: 5px;
}

.modal {
  width: 480px !important;
}
