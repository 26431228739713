@import "scssShared/variables.scss";

.whole-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color_white;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;

  .back-to-login {
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.15em;
    display: block;
  }

  &.full-height {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
  }

  .loading-content {
    text-align: center;

    img {
      width: 120px;
      animation: spin 4s linear infinite;
    }

    h2 {
      margin: 20px 0 0;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      max-width: 340px;
    }

    &.error {
      img {
        animation-play-state: paused;
      }

      .button {
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
