@import "scssShared/variables.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: initial;
}

.button {
  font-size: 16px;
  color: $color_white;
  background-color: transparent;
  outline: none;
  border: none;
  opacity: 0.7;
  transition: opacity 0.1s;
  padding: 3px;
  box-shadow: none;

  .icon {
    margin: 0;
  }

  &:hover,
  &.isOpen {
    opacity: 1;
  }
}

.dropdown {
  $x-offset: 182px;
  $y-offset: 20px;
  z-index: 11;
  position: absolute;
  background-color: $color-white;
  width: 360px;
  right: -$x-offset;
  top: calc(100% + 16px);
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: $border_grey_light_thin;

  .container.altPosition & {
    right: unset;
    left: calc(100% + 5px);
    top: unset;
    bottom: -$y-offset;
  }

  &::before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-left: solid 5px $color_white;
    border-top: solid 5px $color_white;
    border-right: solid 5px transparent;
    border-bottom: solid 5px transparent;
    transform: rotate(45deg) translateX(-50%);
    right: $x-offset;
    top: 0;
    pointer-events: none;
    cursor: default;

    .container.altPosition & {
      border-left: solid 5px $color_white;
      border-top: solid 5px transparent;
      border-right: solid 5px transparent;
      border-bottom: solid 5px $color_white;
      top: unset;
      bottom: calc($y-offset + 4px);
      right: unset;
      left: 0;
    }
  }
}

.topSection {
  padding: 15px;
  border-bottom: $border_grey_light_thin;
}

.bottomSection {
  padding: 10px 15px;
}

.heading {
  font-size: 12px;
  color: $color_grey;
  margin-bottom: 5px;

  strong {
    color: $color_grey_lighter;
  }
}

.docLink {
  text-transform: none;
  text-decoration: underline;
  text-decoration-color: transparent;
  color: $color_black;
  font-size: 14px;
  display: block;
  padding: 5px 0;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color_black;
  }

  .icon {
    font-size: 16px;
    margin-right: 10px;
    width: 16px;
  }

  .openIcon {
    margin-left: 6px;
    margin-bottom: 1px; // optical alignment
    font-size: 11px;
    color: $color_grey_lighter;
  }
}
