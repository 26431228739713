@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

$button-colors: (
  "grey": $color_grey,
  "red": $color_warning_red,
  "black": $color_black_lighter,
  "green": $color_green,
);

@mixin loading-color-styles($color) {
  &.loading {
    &:before {
      border-color: rgba($color, 0.25);
      border-top-color: $color;
    }
  }
}

@mixin outlined-color-styles($color) {
  @include loading-color-styles($color);

  background: $color_white;
  border-color: rgba($color, 0.5);
  color: $color;

  &:hover {
    border-color: rgba($color, 1);
    color: $color;
  }

  &:disabled {
    @include loading-color-styles($color_border);

    color: $color_border;
    border-color: $color_border;
  }
}

@mixin text-button-styles {
  @include loading-color-styles($color_primary);

  background: transparent;
  border: none;
  color: $color_primary;
  padding: 0;

  @each $name, $color in $button-colors {
    &.#{$name} {
      color: $color;

      @include loading-color-styles($color);
    }
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    @include loading-color-styles($color_border);

    color: $color_border;

    &:hover {
      text-decoration: none;
    }
  }

  &.loading {
    &:hover {
      text-decoration: none;
    }
  }
}

.button {
  background: linear-gradient(
    to right,
    $color_primary_lighter 0%,
    $color_primary 50%,
    $color_primary_darker 100%
  );
  background-position: -1px 0%;
  background-size: 200%;
  border: 1px solid transparent;
  border-radius: $br_small;
  color: $color_white;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  height: 34px;
  letter-spacing: 0.15em;
  line-height: normal;
  padding: 6px 12px 5px;
  text-transform: uppercase;
  transition: $transition_default;

  @each $name, $color in $button-colors {
    &.#{$name} {
      background: $color;
      border-color: $color;
    }
  }

  &.loading {
    cursor: wait;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 18px;
      width: 18px;
      margin: -11px 0 0 -11px;

      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      background-clip: padding-box;
      border-color: rgba($color_white, 0.25);
      border-radius: 24px;
      border-style: solid;
      border-width: 2px;
      border-top-color: $color_white;
      content: "";
    }

    span {
      color: transparent;
    }
  }

  &:focus {
    box-shadow: $button_white_focus_box_shadow;
    outline: none;
  }

  &:hover {
    background-position: 50% 0%;
    box-shadow: $bs_large;
  }

  &:disabled {
    background: $color_border;
    border-color: $color_border;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.fullWidth {
    width: 100%;
  }

  &.lg {
    height: 52px;
    font-size: 13px;
    letter-spacing: 0.15em;
    padding-left: 20px;
    padding-right: 20px;

    &.loading {
      &::before {
        height: 24px;
        width: 24px;
        margin: -13px 0 0 -13px;
      }
    }
  }

  &.md {
    height: 44px;
    font-size: 13px;
    letter-spacing: 0.15em;

    &.loading {
      &::before {
        height: 24px;
        width: 24px;
        margin: -13px 0 0 -13px;
      }
    }
  }

  &.xs {
    height: 24px;
    padding: 0 8px;

    font-weight: bold;
    font-size: 9px;
    letter-spacing: 2px;

    &.loading {
      &:before {
        width: 14px;
        height: 14px;
        margin: -9px 0 0 -11px;
      }
    }
  }

  &.outlined {
    @include outlined-color-styles($color_primary);

    @each $name, $color in $button-colors {
      &.#{$name} {
        @include outlined-color-styles($color);
      }
    }

    &.grey {
      @include loading-color-styles($color_grey);

      border-color: $color_border;
      color: $color_grey_lighter;

      &:hover {
        border-color: $color_grey;
        color: $color_black;
      }

      &:disabled {
        color: $color_border;

        &:hover {
          border-color: $color_border;
          color: $color_border;
        }
      }
    }
  }

  &.link {
    @include text-button-styles;

    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &:disabled {
      &:hover {
        text-decoration: underline;
      }
    }

    &.loading {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.text {
    @include text-button-styles;
  }
}

.iconStart {
  margin-right: 8px;
}

.iconEnd {
  margin-left: 8px;
}
