@import "scssShared/variables.scss";

.container {
  position: relative;
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
  margin-bottom: 6px;
  letter-spacing: 1px;
}

.button {
  height: 44px;
  border: $border_grey_light_thin;
  color: $color_black;
  border-radius: $br_small;
  background-color: $color_white;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 0 12px;
  white-space: nowrap;

  &:hover,
  &.isOpen {
    border: $border_grey;
  }

  .icon {
    transition: $transition_default;
    margin-left: 10px;
    font-size: 11px;
    color: $color_grey_lighter;
  }
}

.dropdown {
  position: absolute;
  background-color: $color_white;
  border-radius: $br_large;
  box-shadow: $bs_small;
  border: $border_grey_light_thin;
  z-index: 2;
  top: calc(100% + 10px);

  :global(.calendar-dropdown) {
    border: none;
    box-shadow: none;
  }
}

.dropdownHeader {
  background-color: $color_catskill_white;
  border-bottom: $border_grey_light_thin;
  border-top-left-radius: $br_large;
  border-top-right-radius: $br_large;
  display: flex;
  padding: 10px;
  gap: 10px;
}

.dropdownFooter {
  background-color: $color_catskill_white;
  border-top: $border_grey_light_thin;
  border-bottom-left-radius: $br_large;
  border-bottom-right-radius: $br_large;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.calendarsWrapper {
  display: flex;
  padding: 5px;
  gap: 15px;
}
