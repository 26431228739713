@import "scssShared/variables.scss";

@import "../colorMixins.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: -0.025em;
  line-height: 15px;

  &.primary {
    @each $name, $color in $option-group-colors {
      &.#{$name} {
        b:not(:last-child) {
          color: $color;
        }
      }
    }
  }

  &.secondary {
    b:not(:last-child) {
      color: $color_battleship-grey;
    }
  }

  .nameWrapper {
    border-radius: $br_small;
    display: flex;
    align-items: center;

    &.primary {
      @each $name, $color in $option-group-colors {
        &.#{$name} {
          background: rgba($color, 15%);
          color: $color;
        }
      }
    }

    &.secondary {
      background: rgba($color_battleship-grey, 15%);
      color: $color_battleship-grey;
    }

    .dot {
      border-radius: $br_medium;
      height: 10px;
      width: 10px;
      margin: 5.5px 5px 5.5px 6px;

      &.primary {
        @include insight-background-color;
      }

      &.secondary {
        background: $color_battleship-grey;
      }
    }

    .name {
      padding: 3px 6px 3px 0;
    }
  }
}
