@import "scssShared/variables.scss";

.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;

  background-color: $color_white;
  border: 1px solid $color_very-light-grey;
  box-shadow: $bs_smallest;
  border-radius: $br_large;

  .title {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #999999;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .showMore {
      color: #777777;
      font-size: 12px;
      font-weight: 700;
      height: 30px;
      letter-spacing: normal;
      line-height: 15px;
      margin-left: 5px;
      text-transform: none;
    }

    .loading {
      width: 100px;
      height: 20px;
    }
  }
}
