@import "scssShared/variables.scss";

$next_schedule_color: $color_primary;
$next_schedule_background_color: rgba($color_primary, 0.1);
$past_schedule_color: #999999;
$past_schedule_background_color: linear-gradient(
    0deg,
    rgba(153, 153, 153, 0.1),
    rgba(153, 153, 153, 0.1)
  ),
  #ffffff;

.button {
  padding: 0;
  background: $color_white;
  border-color: $color_border;
  color: $color_grey_lighter;

  &:disabled {
    background-color: $color_white;
  }

  &:focus,
  &:hover {
    box-shadow: none;
  }

  &.hasNextSchedule {
    background: rgba($color_primary, 0.1);
    border-color: $color_primary;
    color: $color_primary;
  }

  &.hasPastSchedule {
    background: $past_schedule_background_color;
    border-color: $past_schedule_color;
    color: $past_schedule_color;
  }

  > span {
    height: 100%;
    display: flex;
    align-items: center;

    .iconWrapper {
      width: 26px;
      height: 26px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: $br_small;

      &.hasNextSchedule,
      &.hasPastSchedule {
        color: $color_white;
        margin-left: 5px;
        margin-right: 5px;
      }

      &.hasNextSchedule {
        background: $next_schedule_color;
      }

      &.hasPastSchedule {
        background: $past_schedule_color;
      }

      .clockIcon {
        font-size: 16px;
      }

      .daysCount {
        width: 11px;
        height: 11px;
        position: absolute;
        right: 2px;
        bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 2px;

        background: $color_white;
        color: $color_primary;
        font-size: 7px;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 50%;
      }
    }

    .text {
      margin-right: 5px;
    }
  }

  .toggleIconWrapper {
    box-sizing: border-box;
    width: 28px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f7f9fb;
    border-radius: 0 $br_small $br_small 0;
    border-left: solid 1px #cccccc;

    &.hasNextSchedule {
      background: rgba($color_primary, 0.1);
      border-color: $next_schedule_color;
    }

    &.hasPastSchedule {
      background: linear-gradient(0deg, rgba(153, 153, 153, 0.1), rgba(153, 153, 153, 0.1)), #ffffff;
      border-color: $past_schedule_color;
    }

    .toggleIcon {
      font-size: 12px;
      font-weight: 900;
      line-height: 12px;

      &.hasNextSchedule {
        color: $next_schedule_color;
      }

      &.hasPastSchedule {
        color: $past_schedule_color;
      }
    }
  }
}
