@import "scssShared/variables.scss";

.insight-tile {
  background: $color_white;
  border: 1px solid $color_border;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: $br_large;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .insight-loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-part {
    height: 40px;
    position: relative;

    h4 {
      height: 30px;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: -0.025em;
      color: $color_primary;
      line-height: 15px;
      position: relative;
      margin: 5px 0 0 0;
      padding: 0 72px 0 36px;
      display: flex;
      align-items: center;
      transition: $transition_default;
      opacity: 1;

      &.tile-name {
        &.fade {
          pointer-events: none;
        }
      }

      &.description-title {
        position: absolute;
        color: $color_grey_lighter;

        .i-icon {
          position: absolute;
          top: 9px;
          left: 13px;
        }
      }

      &.fade {
        opacity: 0;
      }

      .source-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 1px;
        left: 6px;
      }

      .name-wrapper {
        &.small-size {
          font-size: 10px;
          line-height: 12px;
        }

        .name {
          display: inline;
          margin-right: 4px;
          overflow-wrap: break-word;
        }

        .new {
          width: 26px;
          height: 14px;
          box-sizing: border-box;
          border: 1px solid $color_primary;
          border-radius: $br_small;
          text-transform: uppercase;
          font-size: 8px;
          line-height: 10px;
          letter-spacing: 0.05em;
          color: $color_primary;
          display: inline-block;
          text-align: center;
          padding: 1px 0;
          margin-right: 4px;
        }
      }
    }

    .action-buttons {
      width: 70px;
      position: absolute;
      top: 5px;
      right: 5px;

      > * {
        margin-left: 5px;
      }

      .favorite-btn,
      .expand-btn {
        height: 30px;
        width: 30px;
        padding: 0;
      }

      .favorite {
        > svg {
          color: $color_primary;
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .main-content {
    .no-sufficient-data,
    .selection-message {
      font-size: 14px;
      line-height: 135%;
      color: $color_black;
      letter-spacing: -0.025em;
      text-align: center;
      margin: 0 15px;
      font-weight: bold;
    }

    .func-name {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.025em;
      color: $color_black;
      text-align: center;
      margin: 0;
      font-weight: normal;
      padding: 0 5px;

      .between-values {
        display: inline-block;
      }
    }

    .fs-40 {
      font-size: 40px;
      line-height: 48px;
    }

    .fs-32 {
      font-size: 32px;
      line-height: 38px;
    }

    .fs-26 {
      font-size: 26px;
      line-height: 31px;
    }

    .fs-22 {
      font-size: 22px;
      line-height: 26px;
    }

    .fs-18 {
      font-size: 18px;
      line-height: 21px;
    }

    .fs-14 {
      font-size: 14px;
      line-height: 17px;
    }

    .shortening {
      color: $color_grey;
      opacity: 0.75;
    }

    .value {
      margin: 0;
      text-align: center;
      font-weight: bold;
      padding: 0 5px;
      box-sizing: border-box;
      letter-spacing: -0.025em;
      color: $color_black;
      font-feature-settings: "ss01" on;
    }

    .unique-values,
    .common-values {
      letter-spacing: -0.025em;
      text-align: center;
      font-weight: bold;
      margin: 1px 0 0;
      padding: 0 5px;
      font-feature-settings: "ss01" on;
      word-break: break-word;

      &.chart {
        line-height: 100%;
        position: relative;

        .func-name {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          line-height: 16px;
          width: 64px;
          text-align: center;
        }
      }

      .recharts-wrapper {
        margin: 0 auto;

        .chart-label {
          letter-spacing: -0.025em;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
        }
      }

      .recharts-surface {
        border-radius: $br_medium;
      }
    }

    .out-of {
      color: $color_grey;
      letter-spacing: -0.025em;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      margin: 0;
    }

    .of-all {
      color: $color_grey;
      letter-spacing: -0.025;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      margin: 0;
    }

    .custom-tooltip {
      border-radius: $br_small;
      background: rgba($color_black, 0.8);
      color: $color_white;
      max-width: 220px;
      text-align: left;

      p {
        margin: 0;
      }
    }
  }

  .tile-tooltip,
  .custom-tooltip {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    max-width: 395px;
    padding: 10px;
    letter-spacing: normal;
    overflow-wrap: break-word;
  }

  .attribute-info {
    letter-spacing: -0.025em;
    text-align: center;
    font-size: 10px;
    line-height: 12px;

    p {
      margin: 0;
    }

    .label {
      color: $color_grey_lighter;
      font-weight: 500;
    }
    .value {
      font-weight: bold;
      margin-bottom: 8px;
      color: $color_primary;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      h4 {
        color: $color;
      }

      .attribute-info {
        .value {
          color: $color;
        }
      }
    }
  }
}
