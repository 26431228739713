@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.buttons {
  > * {
    margin-left: 10px;
  }
}

.wrapper {
  position: relative;

  .header {
    justify-content: space-between;
  }

  .paper {
    padding: 0;

    .row {
      display: flex;
      padding: 30px 20px;
      margin: 0;

      &.grey {
        background: $color_catskill-white;
        border-top: 1px solid $color_border;
        border-bottom: 1px solid $color_border;
      }

      .left {
        width: 220px;
        flex-shrink: 0;

        h2 {
          font-size: 21px;
          letter-spacing: -0.025em;
        }
      }

      .id {
        width: 350px;
      }

      .right {
        flex-grow: 1;

        .aceEditorWrapper {
          label {
            @include input-label-styles;
          }
          .aceEditor {
            border: $border_grey_light_thin;
            border-radius: $br_small;
            z-index: 0;

            &.error {
              @include input-error-animation;
            }
          }
        }
      }
    }
  }
}
