@import "scssShared/optionGroupColors.scss";

$color_battleship-grey: #8f939f;

@mixin insight-color {
  @each $name, $color in $option-group-colors {
    &.#{$name} {
      color: $color;
    }
  }
}

@mixin insight-background-color {
  @each $name, $color in $option-group-colors {
    &.#{$name} {
      background: $color;
    }
  }
}
