@import "scssShared/variables.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .title {
    margin: 0;
    padding: 0;

    color: #222222;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .description {
    margin: 0;
    padding: 0;

    font-size: 12px;
    line-height: 15px;
    color: #777777;
  }
}

.frequencyCapPaper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &.hasConflicts {
    border-radius: $br_medium $br_medium 0 0;
    border-bottom: none;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .rowFlexBox {
      display: flex;
      justify-content: space-between;

      .title {
        margin: 0;
        padding: 0;

        color: #222222;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 22px;
      }
    }

    .fields {
      display: flex;
      align-items: center;
      gap: 10px;

      .textInput {
        width: 70px;

        input {
          height: 44px;
          border-radius: $br_small 0 0 $br_small;
        }
      }

      .maxCount {
        display: flex;

        .times {
          box-sizing: border-box;
          height: 44px;
          width: 120px;
          display: flex;
          align-items: center;
          padding-left: 10px;

          background: #f7f9fb;
          border: 1px solid #cccccc;
          border-left: none;
          border-radius: 0 4px 4px 0;
          color: #777777;
          font-size: 15px;
          letter-spacing: -0.025em;
          line-height: 100%;
        }
      }

      .text {
        color: #222222;
        font-size: 16px;
        line-height: 19px;
        flex-shrink: 0;
      }

      .period {
        display: flex;

        .periodType {
          width: 120px;
        }
      }
    }

    .buttons {
      align-self: flex-start;
      display: flex;
      align-items: center;
      gap: 10px;

      .toggleButton {
        margin-right: -8px;
      }
    }
  }
}

.conflictingCampaignsPaper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 20px;

  background: #f7f9fb;
  border-radius: 0 0 $br_medium $br_medium;

  .bar {
    min-width: 800px;
    margin: 0 -20px;
    overflow-y: auto;
    position: relative;

    &.notBottom {
      &::after {
        content: " ";
        position: absolute;
        bottom: 0em;
        width: 100%;
        height: 5em;
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, #f7f9fb 100%);
      }
    }

    .campaigns {
      margin: 0 10px;

      .link {
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        gap: 10px;

        border-bottom: 1px solid #dddddd;
        color: #222222;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        text-decoration: none;
        text-transform: none;

        &:hover {
          text-decoration: underline;
        }

        &.noBorder {
          border-bottom: none;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
