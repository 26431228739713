@import "scssShared/variables.scss";

.attributeTable {
  th,
  td {
    &:last-child {
      text-align: end;
    }
  }

  th {
    font-size: 11px;
    text-transform: uppercase;
    color: $color_grey;
    letter-spacing: 0.1em;
  }

  tr {
    border-bottom: none !important;
  }

  td {
    font-size: 14px;
    color: $color_grey_lighter;
    vertical-align: baseline;
    font-weight: normal;

    &.attributeName {
      font-size: 16px;
      line-height: 140%;
      color: $color_black;
      font-weight: bold;
      text-align: left;

      > span {
        color: $color_primary;
        margin-right: 8px;
      }
    }

    &.subAttributeName {
      font-size: 14px;
      line-height: 115%;
      font-weight: bold;
      color: $color_black;
      padding-left: 27px;
      position: relative;

      &:before {
        width: 8px;
        height: 1px;
        top: 18px;
        left: 15px;
        position: absolute;
        background: $color_border;
        content: "";
        display: block;
      }
    }

    &.operation {
      padding: 12px 0;

      &.subFilterOperation {
        padding: 12px 0 12px 15px;
      }

      .operationContainer {
        position: relative;

        .dashedLine {
          border: 0.5px dashed $color_border;
          margin: 0;
          width: 100%;
        }

        .operationLabel {
          position: absolute;
          border-radius: 20px;
          font-size: 11px;
          left: 15px;
          top: -8px;
          padding: 3px 7px 2px 7px;
          color: $color_white;
          background-color: $color_border;
          text-transform: uppercase;
        }
      }
    }
  }

  .attributeName {
    padding-left: 15px;
    width: 40%;
  }

  .attributeOperation,
  .subAttributeOperation {
    width: 30%;
    padding-left: 15px;
  }

  .attributeValue,
  .subAttributeValue {
    padding-right: 15px;
    word-break: break-all;
  }
}
