@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  textarea {
    @include input-styles;
    box-sizing: border-box;
    resize: none;
    width: 100%;
  }

  &.hasError {
    textarea {
      border: $border_warning_red;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
      animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;

      &:hover {
        border-color: $color_warning_red;
      }

      &:focus {
        border-color: $color_warning_red;
        box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
      }
    }
  }

  .label {
    @include input-label-styles;
  }
}
