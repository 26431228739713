@import "scssShared/variables.scss";

@mixin anchor {
  font-size: inherit;
  text-transform: inherit;
}

.container {
  display: flex;
  margin: 0 auto;
  width: 1200px;

  border: $border_grey_light_thin;
  border-radius: $br_large;
  box-shadow: $bs_smallest;

  .image {
    flex: 0 0 740px;
    height: 520px;

    background-color: $color_grey_secondary_menu;
    border-top-left-radius: $br_large;
    border-bottom-left-radius: $br_large;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 40px;

    background-color: $color_white;
    border-bottom-right-radius: $br_large;
    border-left: $border_grey_light_thin;
    border-top-right-radius: $br_large;
    color: $color_black_lighter;
    font-size: 16px;
    line-height: 150%;

    h1 {
      color: $color_primary;
      font-size: 24px;
      line-height: 115%;
      letter-spacing: -0.015em;
    }

    strong {
      a {
        @include anchor;
        color: inherit;
      }
    }

    a {
      @include anchor;
      color: $color_primary;
    }

    > * {
      margin: 0;
      padding: 0;
    }
  }
}
