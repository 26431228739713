@import "scssShared/variables.scss";

.nameFieldRow {
  margin-bottom: 20px;
}

.availablePromoCodeInfo {
  margin: 15px 0 0;
  color: $color_grey;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  strong {
    font-weight: bold;
    letter-spacing: 0.1em;
  }
}

.editableNameValue {
  font-weight: bold;
}

.editableNameValueInput {
  width: 360px;
}

.fileFieldRow {
  .fileField {
    width: 360px;
  }

  .uploadButton {
    margin-top: 5px;
    margin-left: 10px;
  }

  .fileFieldFlexWrapper {
    display: flex;
  }

  .fileFieldDescription {
    color: $color_grey_lighter;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
  }
}
