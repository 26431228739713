@import "scssShared/variables.scss";

.usedAttributesList {
  margin: 0;

  .label {
    font-size: 11px;
    letter-spacing: 1px;
    margin-right: 5px;
    line-height: 24px;
    color: $color_grey;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
  }

  .attributeName {
    margin-right: 5px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
}
