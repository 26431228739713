@import "scssShared/baseVars.scss";

$width: 200px;
$collapsedWidth: 60px;

.container {
  width: $width;
  transition: all 0.1s ease;
  z-index: 6;
  position: fixed;

  &.collapsible {
    width: $collapsedWidth;
    z-index: 11;
  }
}

.innerContainer {
  width: $width;
  background: $color_primary_alternate;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  height: 100vh;
  transition: all 0.1s ease;
  z-index: 100; // TODO:

  .container.collapsed & {
    width: $collapsedWidth;
  }
}

.top {
  display: flex;
  gap: 10px;
  align-items: center;
}

.navWrapper {
  flex: 1;
  overflow: auto;
  position: relative;

  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.topOverlay,
.bottomOverlay {
  height: 40px;
  width: 100%;
  background: transparent;
  z-index: 1;
  pointer-events: none;
}

.topOverlay {
  margin-bottom: -50px;

  &.visible {
    background: linear-gradient($color_primary_alternate, transparent);
  }
}

.bottomOverlay {
  margin-top: -50px;

  &.visible {
    background: linear-gradient(transparent, $color_primary_alternate);
  }
}

.scrollbar {
  position: fixed;
  background-color: transparent;
  width: 4px;
  border-radius: 2px;
  pointer-events: none;
  transition: background-color 0.2s ease;
  z-index: 2;

  &.visible {
    background-color: rgba(white, 0.75);
  }

  .container.collapsed & {
    width: 2px;
    border-radius: 1px;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 2px;
}

.navItem,
.navSubItem,
.navGroup {
  list-style: none;
  margin: 0;
}

.navSubItem {
  margin-right: 5px;
}

.navItem {
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: all 0.1s ease;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.active {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.navSubItem,
.navGroupName {
  font-size: 13px;
}

.navItemLink,
.navSubItemLink {
  color: white;
  text-transform: inherit;
  font-size: inherit;
  text-decoration: none;
  // white-space: nowrap;
}

.navItemLink {
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  .navIcon {
    font-size: 16px;
    width: 20px !important; // TODO: fix this

    .container.collapsed & {
      margin-right: 0;
    }
  }

  .navName {
    opacity: 1;
    transition: all 0.1s ease;
    flex: 1;
  }

  .container.collapsed & .navName,
  .container.collapsed & .expandIcon {
    display: none;
    opacity: 0;
  }
}

.navItemLink {
  opacity: 0.7;
  transition: all 0.1s ease;

  &.active,
  &:hover:not(.disabled) {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}

.navSubItemLink {
  padding: 3px 0;
  margin: 1px 0;
  display: block;
  position: relative;

  .navSubItemName {
    opacity: 0.7;
    transition: all 0.1s ease;
  }

  &.active .navSubItemName,
  &:hover:not(.disabled) .navSubItemName {
    opacity: 1;
  }

  &.disabled .navSubItemName {
    opacity: 0.35;
    cursor: not-allowed;
  }

  &.active {
    &::before {
      position: absolute;
      content: " ";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: white;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.new::after,
  &.beta::after {
    position: absolute;
    display: flex;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 9px;
    padding: 2px 3px;
    border: solid 1px white;
    border-radius: 4px;
    opacity: 0.9;
  }

  &.new::after {
    content: "new";
  }

  &.beta::after {
    content: "beta";
  }
}

.subItemsList {
  padding-left: 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  opacity: 1;
  transition: all 0.1s ease;

  .container.collapsed & {
    opacity: 0;
    display: none;
  }
}

.navGroup:not(:first-child) {
  margin-top: 5px;
}

.navGroupName {
  color: white;
  opacity: 0.7;
}

.navGroupList {
  padding-left: 20px;
}

.collapseButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.collapseButton {
  margin: 10px -20px 10px 0;
  background-color: white;
  color: $color-primary;
  outline: none;
  border: solid 1px rgba($color-primary, 0.25);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  height: 24px;
  width: 28px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  line-height: 1;
  padding-left: 3px;

  .icon {
    opacity: 0.8;
    transition: all 0.1s ease;
  }

  &:hover .icon {
    opacity: 1;
  }

  .container.collapsed & {
    padding-left: 7px;
  }

  display: none;

  .container.collapsible & {
    display: block;
  }
}

.clientLogoWrapper {
  background-color: white;
  padding: 8px 16px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  height: 40px;

  .container.collapsed & {
    padding: 8px 0;
  }
}

.clientLogo {
  height: auto;
  width: auto;
  max-height: 24px;
  max-width: 24px;
}

.clientName {
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: $color_gray_600;
  margin-left: 10px;
  opacity: 1;
  transition: all 0.1s ease;

  .container.collapsed & {
    margin-left: 0;
    display: none;
    opacity: 0;
  }
}
