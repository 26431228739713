@import "scssShared/variables.scss";

.segment-analytics {
  margin-bottom: 10px;

  .segment-analytics-header {
    justify-content: space-between;

    .insights-filter-form {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    .layout-customers-count-wrapper {
      display: flex;

      .segments-insights-layout-picker {
        margin-left: 20px;
      }
    }

    .insight-search {
      width: 200px;
    }
  }

  .tiles-section {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 8px;
    row-gap: 10px;
  }

  .segment-analytics-no-content {
    margin-top: 10px;

    .info-message {
      margin: 20px 10px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      a {
        text-transform: none;
        font-size: 18px;
        font-weight: bold;
        color: $color_primary;
      }
    }
  }
}
