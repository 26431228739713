@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.header {
  display: flex;
  justify-content: space-between;
}

.paper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.search {
  width: 300px;
}

.name {
  word-break: break-word;
}

.date {
  width: 150px;
}

.actions {
  width: 100px;

  :not(:first-child) {
    margin-left: 5px;
  }
}

.modifiedBy {
  font-size: 11px;
}

.marketingContent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  position: relative;

  .bottomLine {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 47.92%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .phone {
    width: 350px;
    height: 360px;
    background: url(./iPhone_off.png) no-repeat;
    background-size: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
  }

  .screen {
    width: 350px;
    height: 100%;
    background: url(./iPhone_on.png) no-repeat;
    background-size: 100%;
    animation-name: unlock;
    animation-duration: 1.25s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes unlock {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.primary {
  color: $color_primary;
  font-weight: bold;
}

.boltIcon,
.warningIcon {
  margin-right: 5px;
}

.boltIcon {
  color: $color_primary;
}

.warningIcon {
  color: #f9b549;
}
