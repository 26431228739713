@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.noResults {
  margin: 0 10px;
  width: auto;

  p {
    font-weight: bold;
    margin: 5px 0;
    text-align: center;
  }
}
