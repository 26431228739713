@import "scssShared/variables.scss";

$option-group-colors: (
  "wasabi": #7f9d43,
  "olivine": #8cba5e,
  "sprout": #99cc66,
  "fun-blue": #2d4b86,
  "persian-blue": #2258c3,
  "jordy-blue": #6c92e0,
  "cerulean-blue": #2929a3,
  "rich-blue": #5959a6,
  "ship-cove": #8484ad,
  "cabaret": #bf4040,
  "charm": #cc6677,
  "can-can": #d27979,
  "california": #e88c30,
  "bronze": #cc8033,
  "whiskey": #cc9966,
  "apple": #5c8547,
  "fuego": #6dc144,
  "granny-smith-apple": #85d65c,
  "atoll": #2d7e86,
  "light-sea-green": #22b6c3,
  "turquoise-blue": #6cd6e0,
  "royal-blue": #556cdb,
  "portage": #8191e4,
  "nepal": #abb6ed,
  "fire-engine-red": #e05252,
  "old-rose": #f07575,
  "contessa": #f5a3a3,
  "broom": #ccac00,
  "macaroni-and-cheese": #e6c100,
  "golden-glow": #f5d73d,
  "sea-green": #17823b,
  "mountain-meadow": #1ab34d,
  "silver-tree": #5cd685,
  "gossamer": #45a16b,
  "medium-aquamarine": #57c785,
  "magic-mint": #67e49b,
  "medium-purple": #8855db,
  "dull-lavender": #a281e4,
  "perano": #c1abed,
  "free-speech-magenta": #e052b1,
  "persian-pink": #f075c7,
  "cupid": #f5a3da,
  "purple-heart": #8d26de,
  "heliotrope": #af5def,
  "perfume": #d9b2f8,
  "gumbo": $color_grey,
  "meiro": $color_primary,
);
