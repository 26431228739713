@import "scssShared/variables.scss";

.container {
  width: 100%;

  .timelineOptionsPaper {
    padding: 0;
    margin-top: 10px;

    .scrollable {
      max-height: calc(95vh - 130px);
      overflow-y: auto;
      padding: 10px;
    }

    .timelineOptionsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 0 10px;
      margin-bottom: 8px;

      .title {
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $color_grey;
      }
    }

    .formRow {
      margin: 0px 0px 18px;
      padding: 0 10px 15px 10px;
      position: relative;
      border-bottom: $border_grey_white_bg;

      .sourcename {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: $color_black;

        &.clickable {
          cursor: pointer;
          transition: $transition_default;
          &:hover {
            opacity: 0.5;
          }
        }

        &.turnedOff {
          color: $color_border;

          &:hover {
            opacity: 1;
            color: $color_black;
          }
        }
      }

      &:after {
        clear: both;
        content: "";
        display: block;
      }

      &:last-child {
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .sourceIcon {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -6px;
        right: 5px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 14px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition_default;
        background: $color_primary;

        @each $name, $color in $colors {
          &.#{$name} {
            background: $color;
          }
        }

        &.disabled {
          background-color: $color_border;
        }

        &:hover {
          opacity: 0.5;
        }

        img {
          max-width: 18px;
          max-height: 18px;
        }
      }

      .caretButton {
        min-width: auto;
        min-height: auto;
        width: 14px;
        height: 18px;
        padding: 0;

        margin-left: 5px;
      }

      .eventTypes {
        margin: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &.collapsed {
          display: none;
        }

        .eventTypeCheckboxWrapper {
          position: relative;

          .selectOnlyButton {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            transition: all 0.1s ease;
            z-index: 1;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $color_primary;
            font-weight: 700;
            background-color: #fff2f0;
            outline: none;
            border: none;
            padding: 4px 6px;
            border-radius: 4px;
            box-shadow: -10px 0px 5px -5px white;
          }

          &:hover .selectOnlyButton {
            pointer-events: all;
            opacity: 1;
          }
        }

        .sub {
          display: flex;
          margin-top: 1px;
          margin-left: 5px;

          font-size: 13px;
          line-height: normal;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:hover {
            color: $color_grey;

            &:has(:checked) {
              color: $color_black_lighter;
            }
          }

          svg {
            padding-top: 3px;
            font-size: 10px;
            line-height: 16px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
