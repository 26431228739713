@import "scssShared/variables.scss";

.search-form-with-select {
  position: relative;
  width: 480px;
  z-index: 2;

  .select-picker {
    width: 200px;
    height: 50px;
    top: 1px;
    left: 1px;
    position: absolute;
    z-index: 2;

    .caret-down {
      font-size: 13px;
    }
  }

  .react-select-redux-field__control {
    height: 50px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $color_border;
    background: #eeeeee;
    box-shadow: none;

    &:hover {
      background: #dddddd;
      border-color: $color_border;
      box-shadow: none;
    }

    &.react-select-redux-field__control--menu-is-open {
      background: $color_primary;
      color: $color_white;

      .react-select-redux-field__placeholder {
        color: $color_white;
      }

      .react-select-redux-field__input {
        color: $color_white;
      }

      .caret-down {
        color: $color_white;
      }
    }
  }

  .react-select-redux-field__indicator-separator {
    display: none;
  }

  .search-field-wraper {
    .search-field {
      input {
        padding-left: 215px;
      }
    }
  }
}
