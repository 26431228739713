@import "scssShared/variables.scss";

.modifiedAt,
.modifiedBy {
  display: block;
}

.modifiedBy {
  font-size: 11px;
}

.yesIcon,
.noIcon {
  font-size: 18px;
}

.yesIcon {
  color: $color_green;
}

.noIcon {
  color: $color_red;
}
