@import "scssShared/variables.scss";

.container {
  width: 100%;
  margin-bottom: 10px;

  .infoTooltip {
    svg {
      font-size: 13px;
    }

    .tooltip {
      pointer-events: auto !important;
    }
  }

  .searchFieldPaper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: $color_grey_secondary_menu;

    > h3 {
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      margin: 0 10px;
    }
  }

  .searchFieldWrapper {
    width: 380px;
    position: absolute;
    left: 50%;
    transform: translateX(-190px);
  }

  .identifiersPaper {
    margin-top: -1px;
    margin-left: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 13px 15px;

    .label {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: $color_grey;
      flex-shrink: 0;
      white-space: pre;
    }

    .identifiers {
      display: flex;
      gap: 5px;
    }

    .identifier {
      display: flex;
      align-items: center;

      .dot {
        display: block;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border: 2px solid black;
        border-radius: 8px;
        transition: $transition_default;
        opacity: 0.3;

        &.active {
          opacity: 1;
        }
      }

      .button {
        background: transparent;
        border: 0;
        text-transform: uppercase;
        font-weight: bold;
        white-space: pre;
        letter-spacing: 0.05;
        font-size: 11px;
        line-height: 13px;
        position: relative;
        transition: $transition_default;
        opacity: 0.3;

        &:focus {
          outline: none;
          border: 0;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
