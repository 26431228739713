@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  padding: 20px;
  display: flex;

  &:nth-child(even) {
    background-color: $color_catskill-white;
  }

  &:not(:last-child) {
    border-bottom: $border_grey_light_thin;
  }

  &:first-child {
    border-top-left-radius: $br_small;
    border-top-right-radius: $br_small;
  }

  &:last-child {
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
  }
}

.description {
  flex: 0 0 220px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 5px;
  }

  p {
    font-size: 12px;
    color: $color_grey_lighter;
    margin: 0;
  }

  a {
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    color: $color_primary;
  }
}

.content {
  flex: 1;
}

.generalSection {
  @extend .section;

  .content {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 220px 220px 100px;
  }
}

.ttlSection {
  @extend .section;

  .content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .ttlInput {
    width: 100px;
  }
}

.schemaSection {
  @extend .section;
  flex-direction: column;
  gap: 10px;

  .schemaTopBar {
    display: flex;

    .schemaTitle {
      width: 500px;
    }
  }

  .schemasWrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: stretch;

    .schemaBox {
      display: grid;
      grid-template-columns: 1fr 150px 150px 1fr min-content;
      align-items: center;
      gap: 10px;
      border-radius: $br_medium;
      border: $border_grey_light_thin;
      padding: 15px;
    }

    .addSchemaButton {
      align-self: flex-start;
    }
  }
}

.autoloadSection {
  @extend .section;

  .autoloadEnable {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    gap: 5px;

    .label {
      @include input-label-styles;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    position: relative;

    .endpointSelect {
      grid-area: 1 / 2 / 2 / 4;
    }

    .eventTimeInput {
      grid-area: 2 / 1 / 3 / 4;
    }

    .userIdInput {
      grid-area: 2 / 4 / 3 / 7;
    }

    .eventFilterInput {
      grid-area: 3 / 1 / 4 / 7;
    }
  }

  .addAutoloadButton {
    margin-left: auto;
  }

  .meConnMessage {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $color_black_lighter;
  }

  .removeAutoloadButton {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.transformationsSection {
  @extend .section;

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.dryRunSection {
  @extend .section;

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .topBar {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    align-self: flex-start;

    .limitInput {
      width: 100px;
    }

    .hint {
      align-self: center;
      font-size: 14px;
      color: $color_grey_lighter;
      margin-top: 26px; // Optical centering
      font-style: italic;
    }
  }

  .payload {
    position: relative;
    align-self: stretch;
    border-radius: $br_large;
    border: $border_grey_light_thin;
    padding: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-size: 15px;
  }

  .controls {
    position: absolute;
    right: 10px;
    top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;

    .pageNumbers {
      white-space: nowrap;
    }
  }

  .label {
    font-weight: 600;
  }

  .eventTime {
    display: flex;
    gap: 30px;
    align-items: center;

    .eventTimeCheck {
      color: $color_black_lighter;
      font-size: 14px;
      display: flex;
      gap: 5px;
      align-items: center;

      .successIcon {
        color: $color_green;
        font-size: 16px;
      }

      .errorIcon {
        color: $color_warning_red;
        font-size: 16px;
      }
    }
  }

  .rawPayload {
    background-color: $color_catskill_white;
    border: $border_grey_light_thin;
    border-radius: $br_small;
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    color: $color_black_lighter;

    pre {
      margin: 0;
      white-space: pre-wrap;
    }
  }

  .error {
    border: solid 1px $color_warning_red;
    background-color: rgba($color_warning_red, 0.1);
    padding: 10px;
    border-radius: $br_medium;
  }

  .emptyMessage {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    font-weight: 600;
  }
}
