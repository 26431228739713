@import "scssShared/variables.scss";

.trash-button-wrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.setup-destinations {
  .setup-destinations-header {
    justify-content: space-between;
  }

  .icon-container {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color_border;
    box-sizing: border-box;
    border-radius: 50%;

    img {
      width: 80%;
      height: auto;
    }
  }

  .setup-destinations-table {
    .icon {
      padding-left: 5px;
      padding-right: 10px;
    }

    .actions {
      .left-margin {
        margin-left: 5px;
      }
    }

    .mi-workspace {
      .tag_select__single-value {
        color: $color_grey_lighter;
      }
    }

    .exported-attributes,
    .mandatory-attributes {
      width: 21%;
    }

    .id {
      width: 5%;
    }

    .icon {
      width: 32px;
    }

    .actions {
      width: 70px;
    }

    .mi-workspace {
      width: 18%;
    }
  }

  .mi-workspace-tooltip {
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
    text-transform: none;
    max-width: 280px;
  }
}
