@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.password-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .text-field {
    flex-grow: 1;
  }

  .label {
    font-size: 8px;
    line-height: 10px;
    margin-top: 5px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
