@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.noResultsFound {
  p {
    font-weight: bold;
    margin: 5px 0;
    text-align: center;

    &.timelineMessage {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $color_grey;
      margin: 10px 0;

      strong {
        font-weight: bold;
        color: $color_primary;
      }
    }
  }
}

.customerTimeline {
  margin-left: 54px;
  flex-grow: 1;

  .groupRow {
    position: relative;
    padding-bottom: 10px;

    &:before {
      content: "";
      display: block;
      width: 2px;
      background-color: $color_border;
      height: 100%;
      position: absolute;
    }

    &:last-of-type {
      padding-bottom: 0;

      &:before {
        height: calc(100% - 42.5px);
        top: 0;
      }

      &:first-child {
        &:before {
          background-color: transparent;
        }
      }
    }

    &:first-child {
      &:before {
        height: calc(100% - 42.5px);
        bottom: 0;
      }
    }

    .timelineText {
      color: $color_grey_lighter;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      position: absolute;
      background-color: $color_grey_site_background;
      display: block;
      padding: 5px 0;
      left: 0;
      top: 42.5px;
      transform: translate(-50%, calc(-50% - 5px));
      max-width: 100px;
      text-align: center;
    }

    .groupBox {
      font-size: 16px;
      font-weight: bold;
      padding: 5px;
      margin-left: 54px;
      width: calc(100% - 54px);
      color: $color_grey_lighter;
      display: flex;

      .countsWrapper {
        display: flex;
        flex-wrap: wrap;

        .countEntry {
          margin: 15px;
          display: flex;
        }

        .iconContainer {
          flex-shrink: 0;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 2px;
          border-radius: 50%;
          background: $color_primary;
          justify-content: center;

          @each $name, $color in $colors {
            &.#{$name} {
              background: $color;
            }
          }

          .sourceIcon {
            max-height: 22px;
            max-width: 22px;
          }
        }

        .countInfo {
          margin-left: 8px;
          > span {
            display: block;
          }
        }

        .count {
          color: $color_black;
          font-size: 16px;
          line-height: 16px;
        }

        .sourceName {
          color: $color_grey;
          font-size: 11px;
          letter-spacing: 1px;
          margin-top: 3px;
          font-weight: 400;
          white-space: nowrap;
        }
      }

      .moreWrapper {
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        padding-right: 10px;

        .groupActionButton {
          width: 28px;
          height: 28px;
          padding: 0;
          position: relative;

          background: $color_white;
          border-color: $color_border;
          border-radius: $br_medium;
          color: $color_grey_lighter;

          &:hover {
            border-color: $color_primary;
            color: $color_primary;
          }

          &.loading {
            border-color: $color_primary;
            cursor: wait;

            svg {
              opacity: 0;
            }

            &:before {
              margin: -10px 0 0 -10px;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 50%;
              top: 50%;
              content: "";
              border-radius: 24px;
              background-clip: padding-box;
              border-color: rgba($color_primary, 0.25);
              border-width: 2px;
              border-style: solid;
              border-top-color: $color_primary;
              animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
            }
          }
        }
      }
    }

    &.opened {
      .openedSticky {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }

  .loadMoreWrapper {
    margin-left: 74px;
    margin-top: 10px;
    width: calc(100% - 94px);
    text-align: center;
  }

  .noResultsFound {
    &:before {
      background-color: transparent;
    }

    &.eventsNotFound {
      margin-left: 74px;
      margin-top: 10px;
      width: calc(100% - 94px);
    }
  }
}
