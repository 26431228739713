@import "scssShared/variables.scss";

.badge {
  color: $color_primary;
  font-size: 8px;
  letter-spacing: 0.05em;
  border: 1px solid $color_primary;
  border-radius: $br_small;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px 4px 0px 5px;
  display: inline-block;
  height: 16px;
  line-height: 16px;
  box-sizing: border-box;
  position: relative;
  top: -1px;

  &.mr {
    margin-right: 4px;
  }
}
