@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

@mixin destination-chip-color($color) {
  background: $color;
  color: $color_white;
  border-color: $color;
  opacity: 1;
}

.segment-export {
  .destination-chip {
    transition: $transition_default;
    background: $color_grey_secondary_menu;
    color: $color_grey;
    border: $border_grey_light_thin;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $br_small;
    position: relative;
    box-sizing: border-box;
    padding-left: 7px;
    padding-right: 7px;

    &:focus {
      outline: none;
    }

    .destination-icon {
      max-width: 20px;
      max-height: 20px;
      display: block;
      opacity: 1;
      transition: $transition_default;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        @include destination-chip-color($color);
      }
    }

    &.green {
      @include destination-chip-color($color_green);
    }

    &.blue {
      @include destination-chip-color($color_blue);
    }
  }

  .destination-info {
    transition: $transition_default;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;

    .destination-customers-count {
      font-size: 10px;
      font-weight: 400;
    }
  }

  .segment-export-settings {
    .segment-export-settings-row {
      border: none;

      .destination {
        .destination-chip-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .segment-export-table {
    .destination {
      width: 250px;
    }

    thead {
      th {
        font-size: 11px;
        color: $color_grey;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    tr {
      td {
        .disabled-export-tooltip {
          font-weight: normal;
          width: 220px;
          font-size: 13px;
          line-height: 135%;
          color: $color_white;
          text-transform: none;
          padding: 10px;
          letter-spacing: normal;
          text-align: left;
        }

        .export-actions {
          display: flex;

          .waiting {
            background: $color_yellow_waiting;
            border-color: $color_yellow_waiting;
            color: $color_white;
          }

          .running {
            background: $color_blue_running;
            border-color: $color_blue_running;
            color: $color_white;
          }

          .cancel {
            margin-left: 5px;
          }
        }
      }

      &:first-child {
        td {
          padding-top: 5px;
        }
      }
    }
  }

  .segment-export-history-table {
    .ban-icon {
      color: $color_grey;
      opacity: 0.5;
    }

    .history-row {
      .history-cell {
        vertical-align: middle;
        padding: 13px 8px 11px;

        .download-icon,
        .log-icon {
          margin: 0 auto;
        }

        &.status {
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 0.1em;
          font-weight: 700;

          &.finished {
            color: $color_green;
          }

          &.canceled {
            color: $color_warning_red;
          }

          &.waiting {
            color: $color_yellow_waiting;
          }

          &.running {
            color: $color_blue_running;
          }

          &.error {
            color: $color_warning_red;
          }
        }
      }
    }

    .history-th {
      padding-top: 10px;
      &.one-icon {
        width: 60px;
      }
    }
  }

  .load-more-button-wrapper {
    margin-top: 15px;
    text-align: center;
  }

  .segment-export-history-header {
    margin-top: 20px;
  }

  .no-exports-message {
    text-align: center;
    color: $color_grey_lighter;
    margin: 20px 0;
  }

  .running-indicator {
    position: relative;
    cursor: wait;
    padding-left: 37px;

    &:before {
      margin-top: -10px;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 9px;
      top: 50%;
      content: "";
      border-radius: 20px;
      background-clip: padding-box;
      border-color: rgba($color_white, 0.25);
      border-width: 2px;
      border-style: solid;
      border-top-color: $color_white;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      box-sizing: border-box;
    }
  }
}
