@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.events-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .info-tooltip-icon {
    font-size: 14px;
    color: $color_grey;
    margin-left: 10px;
  }
}

.events-container {
  .search-form-with-select {
    position: absolute;
    left: 50%;
    margin-left: -240px;
  }

  .events-table {
    .source-col {
      width: 21%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .examples-col {
      width: 704px;
    }

    .event-source {
      font-weight: normal;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      .event-source-flex-wrapper {
        @include flexAcross();
        align-items: center;
        img {
          width: 35px;
          padding-right: 12px;
        }
        .source-n-count {
          @include flexDown();
          .count {
            font-size: 12px;
            color: $color_grey;
          }
        }
      }
    }

    .attribute-tags {
      line-height: 35px;
    }

    .event-examples {
      position: relative;
      padding: 15px;
      .carousel {
        width: 690px;
        position: relative;

        .carousel-content {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
        }

        .carousel-button {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 50%;
          margin-top: -15px;
          background: $color_white;
          border: $border_grey_light_thin;
          border-radius: 50%;
          color: $color_border;
          text-align: center;
          font-size: 15px;
          box-shadow: $bs_smallest;

          &:focus {
            border: $border_grey_light_thin;
            outline: none;
          }

          &:hover {
            box-shadow: $bs_large;
          }

          &.carousel-left {
            left: -15px;
          }

          &.carousel-right {
            right: -15px;
          }
        }

        .event-example {
          width: 100%;
          height: 100%;
          padding: 10px 25px;
          border: $border_grey_light_thin;
          background: #fafafa;
          box-sizing: border-box;
          border-radius: $br_small;
          top: 0px;
          transition: left 0.25s ease-out;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {
            font-size: 16px;
            margin: 0 0 6px;
            word-break: break-word;
            color: $color_grey;
            line-height: 19px;
          }

          .payload {
            color: $color_grey;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
  }
  .tag {
    margin: 0 0 0 5px;
    cursor: pointer;
  }
  .mt-10 {
    margin-top: 10px;
  }
}
