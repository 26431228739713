@import "variables.scss";
@import "mixins.scss";

@include placeholder {
  color: $color_black;
  opacity: 0.5;
}

.form-row {
  position: relative;
  margin: 0 0 18px;
}

.select-field,
.text-field,
.toggle-switch-wrapper,
.checkbox-field {
  label,
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 6px;
    display: inline-block;
    letter-spacing: 1px;
  }

  .full-width {
    width: 100%;
    box-sizing: border-box;
  }

  &.error {
    .error-message {
      margin: 4px 0 0 17px;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold;
      color: $color_warning_red;
    }
  }
}

.text-field {
  input,
  textarea,
  .condition-input {
    @include input-styles;
  }

  textarea {
    resize: none;
  }

  &.error {
    input {
      border: $border_warning_red;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
      animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    &.underlined-text-field {
      input {
        border: none;
        border-bottom: 2px solid $color_warning_red;
        box-shadow: none;
      }
    }
  }

  &.large {
    input {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 15px;
    }
  }
}

.select-field {
  min-width: 140px;
}

.async_select__control {
  .async_select__single-value {
    overflow-x: auto;
    text-overflow: clip;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.async_select__menu {
  .async_select__option {
    word-wrap: break-word;
    overflow-wrap: break-word;

    .count {
      margin-left: 5px;
      color: $color_border;
    }

    &.async_select__option--is-disabled {
      cursor: not-allowed;
      .async_select__option {
        opacity: 0.5;
      }
    }
  }
}

.ace-editor-wrapper {
  .ace-editor {
    border: 1px solid $color_border;
    border-radius: $br_small;
    z-index: 0;
  }

  .error-message {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: $color_warning_red;
  }

  .label-like {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    margin: 0 0 6px;
    letter-spacing: 1px;
  }

  &.error {
    .ace-editor {
      border-color: $color_warning_red;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
      animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
}
