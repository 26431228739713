@import "scssShared/variables.scss";

.users {
  .trash-button-wrapper {
    border-bottom: $border_grey_white_bg;
    padding: 15px 5px 10px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .users-search {
    width: 260px;
  }

  .gravatar {
    .clickable-avatar {
      cursor: pointer;
    }

    width: 48px;

    .avatar-wrapper {
      width: 48px;
      height: 48px;

      .inicials {
        font-size: 13px;
      }
    }
  }

  .admin-users {
    .role-column {
      width: 16%;
    }

    .last-login {
      width: 14%;
    }

    .user-name {
      width: 21%;
    }

    .action-column {
      .trash {
        margin-left: 5px;
      }
    }

    .last-login {
      &.never {
        line-height: 17px;
      }

      > span {
        color: $color_primary;
        font-size: 12px;
      }

      .resend-inv,
      .copy-inv-link {
        display: inline-block;
        font-size: 12px;
        color: $color_primary;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        &.sending,
        &.copying {
          cursor: wait;
        }
      }
    }

    span {
      &.link-hover {
        display: inline-block;
      }
    }

    .disabled-user {
      .user-table-cell {
        color: $color_border;
      }
    }
  }

  .modal-wrapper {
    .modal {
      .form-row {
        .regulations-note {
          font-size: 11px;
          text-align: center;
          margin-bottom: 0;
        }

        &.regulations-note-wrapper {
          margin-bottom: 0;
        }

        &.role-row {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > label {
            margin: 0 10px 0 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
          }
        }

        .role-toggle {
          .fieldset {
            margin: 0;
          }
        }
      }
    }
  }

  .role_select__dropdown-indicator {
    font-size: 11px;
  }
}
