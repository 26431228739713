@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.whisperer {
  position: relative;

  .react-autosuggest__input {
    width: 100%;
    box-sizing: border-box;
    @include input-styles;
  }

  .react-autosuggest__container {
    position: relative;

    .suggestions-label {
      display: none;
    }
  }

  .react-autosuggest__suggestions-container {
    z-index: 1000;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    border-radius: $br_small;
    margin: 5px 0 0;
    padding: 12px 0;

    .suggestions-label {
      display: none;
      font-size: 12px;
      color: $color_grey;
      margin-left: 15px;
    }

    &:not(.react-autosuggest__suggestions-container--open) {
      display: none;
    }
  }

  .react-autosuggest__suggestions-container--open {
    border: 1px solid $color_border;
    background: $color_white;

    .suggestions-label {
      display: block;
    }
  }

  .react-autosuggest__suggestions-list {
    font-size: 15px;
    line-height: 18px;
    max-height: 200px;
    overflow: auto;
    margin: 2px 0 0;
    padding: 0;
  }

  .react-autosuggest__suggestion {
    list-style-type: none;
    overflow-wrap: break-word;

    .whisperer-suggestion {
      padding: 8px 20px 8px 15px;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background: rgba($color_primary, 0.3);
  }

  .most-common {
    margin-left: 5px;
    color: $color_very-light-grey;
  }

  .suggest-loading {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.small {
    input {
      padding: 7px 10px;
    }

    .suggest-loading {
      position: absolute;
      top: 9px;
      right: 9px;
    }
  }
}
