@import "scssShared/variables.scss";

.source-form {
  .form-row {
    display: flex;
    padding: 30px 20px;
    margin: 0;

    &.grey {
      background: $color_catskill-white;
      border-top: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
    }

    &.last {
      margin-bottom: 0;
    }
  }

  .left-part {
    width: 220px;
    flex-shrink: 0;

    h2 {
      font-size: 21px;
      letter-spacing: -0.025em;
      margin: 0;
      line-height: 21px;
      margin-top: 29px;
    }
  }

  label {
    display: block;
  }

  .right-part {
    display: flex;
    flex-grow: 1;
    gap: 10px;

    .source-id,
    .source-type {
      width: 200px;
    }

    .source-name {
      width: 280px;
    }
  }

  .source-description {
    width: 490px;
    box-sizing: border-box;
  }

  .icons-wrapper {
    max-width: 740px;
  }

  .colors-wrapper {
    max-width: 740px;
  }

  .label-n-tooltip {
    margin: 25px 0 -5px 0;

    label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
      color: $color_grey;
      margin-bottom: 3px;
    }
  }
}
