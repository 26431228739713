@import "scssShared/variables.scss";

.featuredSegments {
  .searchBar {
    width: 280px;
  }

  .segmentName {
    font-size: 16px;
    font-weight: 700;
    color: $color_black;
    display: flex;
    align-items: center;
    padding: 10px 0;
    max-width: 100%;

    .segmentNameText {
      word-wrap: break-word;
      min-width: 0;
    }

    .schedulingIcon {
      width: 22px;
      height: 20px;
      color: $color_grey;
      border-radius: $br_small;
      border: 1px solid $color_border;
      box-sizing: border-box;
      background: #f9f9f9;
      margin-right: 12px;
      flex-shrink: 0;
      font-size: 11px;
      font-weight: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:not(.active) {
        &:after {
          content: "";
          width: 16px;
          height: 3px;
          box-sizing: border-box;
          border-top: 1px solid #f9f9f9;
          border-bottom: 1px solid #f9f9f9;
          position: absolute;
          background-color: $color_grey;
          transform: rotate(-45deg);
        }
      }

      &.active {
        background: rgba(254, 127, 102, 0.1);
        border-color: $color_primary;
        color: $color_primary;
      }
    }
  }

  .tagsCell {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .tag {
      cursor: pointer;
    }
  }

  .modifiedBy {
    font-size: 11px;
  }

  .tagsRow {
    display: flex;
    align-items: center;
    padding: 5px 5px 10px 5px;
    border-bottom: $border_grey_white_bg;

    .tagsFilter {
      flex: 1;
      display: flex;
    }

    .label {
      font-size: 11px;
      letter-spacing: 1px;
      margin-right: 10px;
      line-height: 24px;
      color: $color_grey;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
    }

    .tagsAndPicker {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .tag {
        margin-right: 5px;
      }
    }
  }

  .disabledSegment > div {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
