@import "scssShared/variables.scss";

@import "../colorMixins.scss";

.root {
  display: flex;
  flex-direction: column;

  .label {
    color: $color_black;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 16px;
  }

  .chart {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .horizontalFlex {
      display: flex;
      align-items: center;

      .verticalFlex {
        display: flex;
        flex-direction: column;

        &.relative {
          position: relative;
        }

        .horizontalLine {
          height: 2px;

          background: $color_very-light-grey;
          border-radius: 1px;

          &.hidden {
            height: 0;
          }
        }
      }

      .verticalLine {
        height: 12px;
        width: 2px;

        background: $color_very-light-grey;
        border-radius: 1px;
      }

      .oneHundred {
        margin-left: 5px;

        color: $color_very-light-grey;
        font-size: 12px;
        letter-spacing: -0.025em;
        line-height: 16px;
      }
    }

    .legend {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > *:not(:last-child) {
        margin-bottom: 4px;
      }

      span {
        font-size: 11px;
        letter-spacing: -0.025em;
        line-height: 13px;

        &.primary {
          @include insight-color;
        }

        &.secondary {
          color: $color_battleship-grey;
        }
      }
    }
  }
}

.tooltipContent {
  display: flex;

  .paragraph {
    font-size: 12px;
    line-height: 15px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    height: 16px;
    width: 11px;

    svg {
      font-size: 16px;
      font-weight: 900;
      line-height: 16px;
      height: 16px;
      width: 11px;
    }
  }
}
