@import "scssShared/variables.scss";

.container {
  margin-top: 10px;
  transition: all 0.1 ease;
  font-size: 15px;
  line-height: 1.25;

  span,
  strong {
    // To close gaps between lines of text to prevent flickering when moving cursor across lines
    padding: 1px 0;
    margin: -1px 0;
  }

  .message {
    color: $color_grey_lighter;
  }

  &.isHovering {
    color: $color_grey;
  }

  .condition {
    transition: all 0.1 ease;

    &:hover {
      color: $color_primary;
    }
  }

  .value {
    word-break: break-word;
  }
}
