@import "scssShared/variables.scss";

@import "../colorMixins.scss";

.valueWrapper {
  display: flex;
  align-items: center;

  .value {
    text-align: right;

    &.primary {
      @include insight-color;

      font-size: 56px;
      line-height: 68px;

      &.small {
        font-size: 32px;
        line-height: 39px;
      }
    }

    &.secondary {
      color: $color_battleship-grey;
      font-size: 32px;
      line-height: 39px;

      &.small {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 14px;
    line-height: 17px;

    &.secondary {
      color: $color_battleship-grey;
    }
  }
}
