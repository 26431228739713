@import "scssShared/baseVars.scss";

// colors
$color_primary: $color_primary;

$color_primary_lighter: #fea172;
$color_primary_darker: #eb6c52;

$color_black: $color_gray_800;
$color_grey_darker: $color_gray_700;
$color_black_lighter: $color_gray_600;
$color_grey_lighter: $color_gray_500;
$color_grey: $color_gray_400;
$color_very-light-grey: $color_gray_300;
$color_grey_fa: $color_gray_50;
$color_white: #ffffff;

$color_warning_red: $color_red;
$color_warning_orange: $color_orange;
$color_green: $color_green;
$color_yellow_waiting: $color_yellow;
$color_blue_running: $color_blue;

$color_catskill-white: $color_gray_50;

$color_border: $color_gray_300;
$color_disabled_field_bg: $color_gray_200;
$color_disabled_field: $color_gray_600;
$color_grey_secondary_menu: $color_gray_100;

$color_bg_customer_avatar: #feefec;
$color_link_header: #fec5ba;
$color_grey_site_background: #eaeef1;

$color_okta: #01579b;

$colors: (
  "wasabi": #7f9d43,
  "olivine": #8cba5e,
  "sprout": #99cc66,
  "fun-blue": #2d4b86,
  "persian-blue": #2258c3,
  "jordy-blue": #6c92e0,
  "cerulean-blue": #2929a3,
  "rich-blue": #5959a6,
  "ship-cove": #8484ad,
  "cabaret": #bf4040,
  "charm": #cc6677,
  "can-can": #d27979,
  "california": #e88c30,
  "bronze": #cc8033,
  "whiskey": #cc9966,
  "apple": #5c8547,
  "fuego": #6dc144,
  "granny-smith-apple": #85d65c,
  "atoll": #2d7e86,
  "light-sea-green": #22b6c3,
  "turquoise-blue": #6cd6e0,
  "royal-blue": #556cdb,
  "portage": #8191e4,
  "nepal": #abb6ed,
  "fire-engine-red": #e05252,
  "old-rose": #f07575,
  "contessa": #f5a3a3,
  "broom": #ccac00,
  "macaroni-and-cheese": #e6c100,
  "golden-glow": #f5d73d,
  "sea-green": #17823b,
  "mountain-meadow": #1ab34d,
  "silver-tree": #5cd685,
  "gossamer": #45a16b,
  "medium-aquamarine": #57c785,
  "magic-mint": #67e49b,
  "medium-purple": #8855db,
  "dull-lavender": #a281e4,
  "perano": #c1abed,
  "free-speech-magenta": #e052b1,
  "persian-pink": #f075c7,
  "cupid": #f5a3da,
  "purple-heart": #8d26de,
  "heliotrope": #af5def,
  "perfume": #d9b2f8,
);

// fonts
$font_family_neue: "Neue Hans Kendrick", sans-serif;
$font_family_inter: "Inter UI", sans-serif;

// border radius
$br_small: 4px;
$br_medium: 6px;
$br_large: 8px;

// border types
$border_grey_white_bg: 1px solid #e6e6e6;
$border_grey_light_thin: 1px solid $color_border;
$border_grey: 1px solid $color_grey;
$border_primary_thin: 1px solid $color_primary;
$border_warning_red: 1px solid $color_warning_red;

// tables
$table_row_hover: rgba(254, 127, 102, 0.05);
$table_row_link_hover: rgba(254, 127, 102, 0.1);

// dropped box shadows
$bs_smallest: 0px 3px 6px rgba(0, 0, 0, 0.05);
$bs_small: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
$bs_large: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
$button_primary_focus_box_shadow: 0 0 4px 1.5px rgba($color_primary, 0.4);
$box_shadow_toggle_active: 0px 2px 4px rgba(0, 0, 0, 0.15);
$button_white_focus_box_shadow: 0 0 4px 1.5px rgba($color_grey, 0.4);

// inset box shadows
$toggle_inset_box_shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);

// transition
$transition_short: all 0.1s ease;
$transition_default: all 0.15s ease-out;
$transition_long: all 0.5s ease;
