@import "scssShared/variables.scss";

.body {
  padding: 20px;
}

.emptyMessage {
  display: flex;
  justify-content: center;
  color: $color_grey_lighter;
}

.historyTable {
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  gap: 15px 20px;
}

.header {
  display: contents;
  font-size: 11px;
  text-transform: uppercase;
  color: $color_grey;
  letter-spacing: 1px;
  font-weight: 700;
}

.row {
  display: contents;

  & > div {
    display: flex;
    align-items: center;

    &.segments {
      gap: 4px;
      flex-wrap: wrap;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  grid-column: 1 / -1;
  margin: 0;
  padding: 0;

  border: none;
  background-color: #cccccc;

  &:last-of-type {
    display: none;
  }
}

.rowSpinner {
  height: 16px;

  &::before {
    height: 16px;
    width: 16px;
  }
}

.timestamp {
  font-size: 15px;
  color: $color_grey_lighter;
}

.status {
  text-transform: uppercase;
  font-family: $font_family_neue;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;

  &.status__finished {
    color: $color_green;
  }

  &.status__canceled {
    color: $color_warning_red;
  }

  &.status__waiting {
    color: $color_yellow_waiting;
  }

  &.status__running {
    color: $color_blue_running;
  }

  &.status__error {
    color: $color_warning_red;
  }
}

.loadMore {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
