@import "scssShared/variables.scss";

.search {
  width: 300px;
}

.buttons {
  display: flex;
  gap: 5px;
}

.nameWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.badge {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.05em;
  border: $border_grey_light_thin;
  padding: 4px 6px;
  line-height: 1;
  border-radius: $br_medium;
}
