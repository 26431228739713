@import "scssShared/variables.scss";

.condition-operation-wrapper {
  position: relative;

  .condition-operation-dropdown-button {
    border: $border_grey_light_thin;
    border-radius: $br_small;
    background-color: $color_white;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    transition: $transition_default;
    letter-spacing: 0;
    line-height: normal;
    box-shadow: none;
    padding: 0 10px;
    width: 220px;
    height: 34px;

    &:disabled {
      background-color: $color_disabled_field_bg;
      color: $color_disabled_field;
      opacity: 1;
    }

    & > span {
      display: flex;
      align-items: center;
    }

    &:hover:not(:disabled) {
      border: solid 1px $color_grey;
    }

    .text {
      flex: 1;
      text-align: left;
    }

    .placeholder {
      color: $color_grey_lighter;
    }

    .caret-wrapper {
      margin-left: 10px;
      padding-left: 7px;
      border-left: $border_grey_light_thin;
      color: $color_grey_lighter;
      font-size: 11px;
      height: 18px;
      display: flex;
      align-items: center;
    }

    &.error-shadow {
      border-color: $color_warning_red;

      &.is-visible {
        animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }

  .dropdown {
    background: $color_white;
    border-radius: $br_small;
    border: $border_grey_light_thin;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    margin-top: 5px;
    width: 280px;
    min-height: 50px;
    display: flex;

    &:after {
      display: table;
      content: "";
      clear: both;
    }

    .context-menu {
      width: 60px;
      padding: 5px 0;
      background: $color_grey_secondary_menu;
      border-right: $border_grey_light_thin;
      text-align: center;
      float: left;

      .menu-item {
        float: none;
        padding: 5px;
        opacity: 1;
        color: $color_grey;

        &:hover {
          color: $color_primary;
          cursor: pointer;
        }

        &.selected {
          color: $color_primary;
        }

        &.disabled {
          color: $color_border;
          cursor: not-allowed;

          &:hover {
            color: $color_border;
            cursor: not-allowed;
          }
        }

        button {
          color: inherit;
          cursor: inherit;

          &:hover {
            opacity: 1;
          }
        }

        span {
          color: inherit;
          cursor: inherit;
          font-size: 7px;
          font-weight: bold;
          line-height: 8px;
          display: block;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
      }
    }

    .context-condition-operations {
      float: left;
      padding: 15px;
      width: 188px;

      span {
        display: block;
        font-weight: bold;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color_grey_lighter;
        cursor: pointer;
        transition: $transition_default;
        margin: 0 0 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $color_primary;
        }

        &.active {
          color: $color_primary;
        }

        &.disabled {
          color: $color_border;
          cursor: not-allowed;
        }
      }
    }
  }
}
