@import "scssShared/variables.scss";

.reportingHeader {
  flex-wrap: wrap;
  padding-bottom: 5px;

  .sectionButton {
    margin-right: 5px;
    background: transparent;
    font-weight: bold;
    color: $color_grey;
    margin-bottom: 5px;

    &.active {
      background: $color_white;
      color: $color_black;
    }
  }
}

.content {
  padding: 20px;
}

.embed {
  width: 100%;
  border: 0;
}

.iframeWrapper {
  position: relative;
  min-height: 34px;

  .iframeLoading {
    position: absolute;
  }

  iframe {
    background-color: $color_white;
    position: relative;
  }
}

.postScript {
  font-size: 14px;
}
