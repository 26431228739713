.header {
  justify-content: space-between;
}

.modified {
  width: 190px;
}

.action {
  width: 100px;
}
