@import "scssShared/variables.scss";

.container {
  border: $border_grey_light_thin;
  padding: 10px;
  border-radius: $br_large;
  background-color: white;
  display: flex;
  gap: 20px;
  align-items: center;
}

.dragHandle {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_grey;
  border-radius: 8px;
  cursor: grabbing;
  transition: all 0.1s ease;
  flex: 0 0 26px;

  &:hover {
    background-color: rgba($color_grey, 0.25);
    color: $color_grey_lighter;
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.topRow {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 20px;
  align-items: center;
}

.srcDstRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
}

.valuesMapRow {
  display: grid;
  grid-template-columns: auto auto max-content;
  gap: 10px;
  align-items: center;
  border-radius: $br_large;
  border: $border_grey_light_thin;
  padding: 10px;
}

.addMappingButton {
  align-self: flex-start;
}
