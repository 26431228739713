@import "scssShared/variables.scss";

.admin-roles {
  .trash-button-wrapper {
    border-bottom: $border_grey_white_bg;
    padding: 15px 5px 10px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .admin-roles-header {
    justify-content: space-between;

    .admin-roles-header-name {
      width: 350px;

      label {
        color: $color_grey;
        font-size: 13px;
        letter-spacing: 0.1em;
        line-height: 16px;
        margin: 0 10px 0 0;
      }

      input {
        border-radius: $br_medium;
      }
    }

    .right-part {
      button {
        margin-left: 10px;
      }
    }
  }

  .admin-roles-content {
    .role-name {
      width: 20%;
    }

    .feature {
      margin: 2.5px 5px 2.5px 0;

      &:hover {
        box-shadow: none;
      }
    }

    .trash-button {
      margin-left: 5px;
    }

    .action-column {
      width: 60px;
    }
  }

  .feature-tooltip {
    max-width: 300px;
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    letter-spacing: 0;
    padding: 10px;
  }

  .role-form {
    .on-off-toggle {
      .fieldset {
        margin-bottom: 0;
        height: 28px;
      }
      input[type="radio"] {
        height: 28px;
      }
      .switch {
        height: 22px;
        font-size: 13px;
        padding-top: 4px;
      }
    }

    .form-content {
      display: flex;
      justify-content: space-between;

      .all-features-actions {
        display: flex;
        margin: 8px 10px 0;

        .button {
          font-weight: bold;
          font-size: 13px;
          text-transform: none;
          letter-spacing: normal;
        }

        .buttons-delimiter {
          width: 1px;
          height: 24px;
          background: $color_border;
          margin: 0 10px;
        }
      }

      .features-section {
        margin-top: 10px;
        padding: 15px;

        .header-part {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
        }

        h4 {
          margin: 8px 0 0 10px;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .left-column,
      .right-column {
        width: calc(50% - 5px);
        flex-shrink: 0;

        .row {
          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .row {
        border-bottom: 1px solid $color_border;
        .main {
          padding: 8px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > label {
            font-size: 13px;
            font-weight: bold;

            .info-icon {
              color: $color_grey_lighter;
              font-size: 10px;
              margin-left: 2px;
            }
          }
        }
        .secondary {
          padding-bottom: 4px;

          .secondary-row {
            padding: 4px 25px 4px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > label {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.attribute-visibility-settings {
  &:before {
    content: "";
    display: block;
    width: calc(100% - 20px);
    height: 1px;
    background-color: $color_very-light-grey;
    margin: 20px 10px;
  }
}

.attribute-visibility-box {
  padding: 15px;

  h4 {
    margin: 8px 0 0 10px;
  }

  .attribute-visibility-tooltip {
    color: $color_grey;
  }
}

.attribute-visibility-labels {
  margin: 10px 10px 0;

  .attribute-visibility-labels-list {
    display: flex;

    > span {
      margin-right: 5px;
    }
  }
}
