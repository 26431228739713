@import "scssShared/variables.scss";

.header {
  display: flex;
  gap: 10px;
}

.config {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.configRow {
  display: flex;
  padding: 30px;

  &:nth-child(even) {
    background: $color_catskill-white;
    border-top: 1px solid $color_border;
    border-bottom: 1px solid $color_border;

    &:last-child {
      border-bottom-left-radius: $br_medium;
      border-bottom-right-radius: $br_medium;
      border-bottom: none;
    }
  }
}

.description {
  flex-basis: 30%;
  display: flex;
  align-items: center;

  h3 {
    color: #222222;
    font-size: 21px;
    letter-spacing: -0.025em;
    line-height: 25px;
    margin: 0;
  }

  p {
    color: #777777;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
  }
}

.configDetails {
  flex-basis: 70%;
}

.firebaseDetails {
  margin-bottom: 20px;
  font-size: 14px;
  color: $color_grey_lighter;
}

.fileUploadField {
  width: 400px;
}

.submitButton {
  margin-top: 5px;
  margin-left: 10px;
}

.fileUploadDesc {
  color: $color_grey_lighter;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.configuredMarkup {
  color: $color_primary;
  border: 1px solid $color_primary;
  border-radius: 12px;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  line-height: 22px;
  height: 24px;
  box-sizing: border-box;
  padding: 0 8px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
