@import "scssShared/variables.scss";

.tags-search {
  width: 315px;
}

.data-tags {
  .tag-form-buttons {
    display: flex;
    gap: 10px;
  }

  .data-tags-header {
    justify-content: space-between;

    .search-form {
      width: 320px;
      position: absolute;
      left: 50%;
      margin-left: -160px;
    }
  }

  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    a {
      text-transform: none;
      font-size: 18px;
      font-weight: bold;
      color: $color_primary;
    }
  }

  .tag {
    cursor: pointer;
    margin: 8px 0;
  }

  .actions-cell {
    display: flex;
    align-items: center;
    gap: 10px;

    .segments-link {
      text-decoration: underline;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $color_grey_lighter;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .buttons {
      display: flex;
      gap: 5px;
    }
  }

  .tag-form-wrapper {
    padding: 30px 12px 30px 20px;
    box-sizing: border-box;

    .colors-wrapper {
      max-width: 750px;
    }

    .tag-form {
      display: flex;
      justify-content: space-between;

      .label-like {
        display: block;
        color: $color_grey;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0 0 5px 0;
        font-weight: bold;
      }

      .custom-text-field label {
        margin-bottom: 13px;
      }

      .colors-buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-left: 25px;

        .colors-box {
          margin-right: 20px;
        }

        .buttons {
          display: flex;
          margin-bottom: 8px;
          margin-top: 25px;
        }
      }
    }
  }

  .tags-info-message {
    padding-top: 20px;
  }
}
