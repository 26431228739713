@import "scssShared/variables.scss";

.modalText {
  color: #222222;
  font-size: 17px;
  line-height: 26px;
}

.bar {
  overflow-y: auto;
  margin: 0 -30px;
  position: relative;

  &.notBottom {
    &::after {
      content: " ";
      position: absolute;
      bottom: 0em;
      width: 100%;
      height: 5em;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }

  .campaigns {
    margin: 0 20px;

    .link {
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;

      border-bottom: 1px solid #dddddd;
      color: #222222;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-decoration: none;
      text-transform: none;

      &:hover {
        text-decoration: underline;
      }

      &.noBorder {
        border-bottom: none;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
