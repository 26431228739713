@import "scssShared/variables.scss";

.container {
  .entry {
    margin-bottom: 5px;
    width: 100%;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.error) {
    color: $color_red;
  }

  p {
    font-size: 13px;
    margin: 0 0 5px;
    font-weight: 500;
  }

  h5 {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0 5px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 16px;

    li {
      font-size: 13px;
      font-weight: 400;
    }
  }

  a {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    color: $color_grey;
    font-weight: normal;

    &:hover {
      color: $color_grey;
      text-decoration: none;
    }
  }
}
