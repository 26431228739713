@import "scssShared/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;
}

.addTransformationButton {
  align-self: flex-start;
}
