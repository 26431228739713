.paragraph {
  font-size: 16px;
  letter-spacing: -0.015em;
  line-height: 145%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
