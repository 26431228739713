@import "scssShared/variables.scss";

.identity-stitching-detail {
  .header-buttons {
    > * {
      margin-left: 10px;
    }
  }

  .identity-stitching-detail-content {
    padding: 0;
  }
}
