@import "scssShared/variables.scss";
@import "scssShared/optionGroupColors.scss";

@import "../../colorMixins.scss";

.bar {
  height: 16px;
  position: relative;
  text-align: center;

  color: $color_white;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 16px;
  transition: width 0.2s ease-in-out;

  &.withinMultiBar {
    position: absolute;
  }

  &.colored {
    @include insight-background-color;
    border-radius: 2px 2px 0 0;

    &.backBar {
      @each $name, $color in $option-group-colors {
        &.#{$name} {
          background: rgba($color, 60%);
        }
      }
    }

    &.single {
      border-radius: 2px;
    }
  }

  &.grey {
    background: $color_battleship-grey;
    border-radius: 0 0 2px 2px;

    &.backBar {
      background: rgba($color_battleship-grey, 60%);
    }
  }

  span {
    white-space: pre;

    &.nextTo {
      position: absolute;

      &.colored {
        @include insight-color;
      }

      &.grey {
        color: $color_battleship-grey;
      }

      &.withinMultiBar {
        color: $color_white !important;
      }
    }
  }
}

.line {
  height: 2px;
  position: absolute;
  top: 7px;
  right: 0px;

  background: $color_very-light-grey;
  border-radius: 1px;
}
