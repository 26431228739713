@import "scssShared/variables.scss";

.link {
  text-transform: none;
  text-decoration: none;

  &.disabled {
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }
}

.chip {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 4px;

  background-color: $color_white;
  border: 1px solid #dddddd;
  border-radius: $br_large;

  &:hover {
    border-color: $color_primary;
    box-shadow: 0px 0px 0px 3px rgba(254, 127, 102, 0.2);

    .icon {
      svg {
        color: $color_primary;
      }
    }

    .name {
      color: $color_primary;
    }
  }

  &.disabled {
    background-color: #e6e6e6;
    border-color: #cccccc;
    box-shadow: none;

    .icon {
      svg {
        color: #999999;
      }
    }

    .name {
      color: #777777;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;

    svg {
      color: #555555;
      font-size: 10px;
      font-weight: 900;
      line-height: 10px;
    }
  }

  .name {
    font-size: 13px;
    line-height: 16px;
    color: #555555;
  }

  &.filled {
    gap: 5px;
    border-color: #cccccc;

    &:hover {
      border-color: $color_primary;

      .icon {
        background-color: rgba(254, 127, 102, 0.2);

        svg {
          color: $color_primary;
        }
      }

      .name {
        color: $color_primary;
      }
    }

    &.disabled {
      border-color: #cccccc;

      .icon {
        background-color: #dddddd;

        svg {
          color: #999999;
        }
      }

      .name {
        color: #777777;
      }
    }

    .icon {
      background-color: #dddddd;
      border-radius: $br_medium;

      svg {
        color: #999999;
      }
    }

    .name {
      font-size: 12px;
      line-height: 15px;
      color: #777777;
    }
  }
}
