@import "scssShared/variables.scss";

.detail-destination {
  .header-buttons {
    > * {
      margin-left: 10px;
    }
  }

  .detail-destination-content {
    padding: 0;
  }
}
