@import "variables.scss";

/*
 * For mixin to work both nested and unnested
 */
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

/*
 * Sass form input placeholder css styling
 *
 * Usage:
 *	@include placeholder {
 *   	font-style:italic;
 *   	color: white;
 *   	font-weight:100;
	*	}
 */
@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }
  @include optional-at-root(":-moz-placeholder") {
    @content;
  }
  @include optional-at-root("::-moz-placeholder") {
    @content;
  }
  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.5) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin input-label-styles {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 6px;
  display: inline-block;
  letter-spacing: 1px;
}

@mixin input-styles {
  border: $border_grey_light_thin;
  border-radius: $br_small;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 15px;
  transition: $transition_default;
  background: $color_white;

  &:hover {
    border: $border_grey;
  }

  &:focus {
    outline: none;
    border: $border_primary_thin;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
  }

  &:read-only,
  &:disabled {
    border: $border_grey_light_thin;
    background: #e6e6e6;

    &:hover {
      cursor: not-allowed;
    }
  }
}

@mixin input-error-message-styles {
  margin: 4px 0 0 17px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: $color_warning_red;
}

@mixin input-error-animation {
  border: $border_warning_red;
  box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
  animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
