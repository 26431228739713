@import "scssShared/variables.scss";

.setup-labels {
  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .buttons {
    > * {
      margin-left: 10px;
    }
  }

  .left-margin {
    margin-left: 5px;
  }

  .labels-table {
    .actions {
      width: 100px;
    }

    .label-created {
      width: 150px;
    }
  }

  .label-form-wrapper {
    padding: 30px 12px 30px 20px;
    box-sizing: border-box;

    .label-form {
      display: flex;

      .custom-text-field {
        min-width: 130px;
      }

      .label-like {
        display: block;
        color: $color_grey;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0 0 5px 0;
        font-weight: bold;
      }
    }
  }
}
