@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .modal {
    width: 100%;
    background-color: $color_white;
    border-radius: $br_small;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 101;
    padding: 30px;
    width: 420px;

    &.small {
      width: 320px;
    }

    &.large {
      width: 720px;
    }

    header {
      position: relative;
      h3 {
        color: $color_grey;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 15px;
        min-height: 16px;
      }

      .close-button {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }

    .form-row {
      &:last-of-type {
        margin-bottom: 25px;
      }
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancel-button {
      margin-left: -11px;
    }
  }

  .modal-overlay {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba($color_black, 0.5);
  }
}
