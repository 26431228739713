@import "scssShared/variables.scss";

.wrapper {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  margin-top: 4px;
  width: 240px;
}

.bar {
  box-sizing: border-box;
  width: 240px;
  height: 70px;
  padding: 15px;
  overflow: hidden;

  background: $color_white;
  border: 1px solid #cccccc;
  border-radius: $br_large;
  box-shadow: $bs_smallest;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.15s ease-out;

  li {
    color: #999999;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 15px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
