@import "scssShared/variables.scss";

.analytics-dashboard {
  .info-tooltip-icon {
    margin: 0 auto 0 10px;
    color: $color_grey;
    font-size: 14px;
  }

  .dashboard-tooltip-icon {
    display: flex;
  }

  .dashboard-header {
    margin-bottom: 10px;
    height: 85px;
    display: flex;
    justify-content: space-between;

    .paper-header,
    .paper {
      width: auto;
      height: 85px;
    }

    .diagnostic-values {
      flex-grow: 1;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0;

      .delimiter {
        width: 1px;
        height: 65px;
        background: $color_border;
        margin-top: 9px;
        flex-shrink: 0;
      }

      .customers-count {
        flex: 2;
        margin-right: 20px;
        padding-left: 20px;

        .box-chart-title {
          z-index: 3;
        }

        .no-of-customers-tooltip {
          .tooltip {
            max-width: 200px;
            pointer-events: auto !important;
            z-index: 10;

            &:hover {
              visibility: visible !important;
              opacity: 1 !important;
            }
          }
        }
      }

      .sources-count {
        margin: 0 20px;
        flex: 1;
      }

      .attributes-count {
        flex: 1;
        margin: 0 20px;
      }

      .customer-attributes-count {
        flex: 1.5;
        margin: 0 20px;
      }

      .events-count {
        flex: 2;
        margin-left: 20px;
        padding-right: 20px;
      }

      .box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 69px;

        &.customers-count {
          height: 60px;
        }

        .value {
          font-size: 24px;
          text-align: left;
          letter-spacing: -0.05em;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "salt" on;

          &.relative {
            position: relative;
            .before-stitching {
              .val {
                text-transform: uppercase;
              }

              display: block;
              position: absolute;
              color: $color_black;
              font-size: 12px;
              line-height: 13px;
              letter-spacing: normal;
              text-transform: none;
              font-weight: 400;
              bottom: -13px;
            }
          }
        }

        .box-chart-title {
          min-height: 24px;
          max-height: 29px;
          height: auto;
          display: flex;
          align-items: flex-end;

          h3 {
            padding-top: 7px;
          }
        }

        &.loading {
          &:before {
            margin: 0px 0 0 -11px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .last-cache-update {
      display: block;
      padding: 13px 15px 12px 20px;
      width: 13.333%;
      min-width: 120px;

      h4 {
        margin: 0;
        font-weight: bold;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color_grey;
      }

      .timeago {
        color: $color_grey;
        font-size: 14px;
        margin-top: 16px;
        line-height: 29px;

        strong {
          font-size: 24px;
          letter-spacing: -0.05em;
          text-transform: uppercase;
          font-feature-settings: "ss02" on, "salt" on;
          color: $color_black;
          font-weight: 500;
        }
      }

      .refresh-in-progress {
        font-size: 11px;
        line-height: 14px;
        color: $color_grey;
        display: block;
        margin-top: 12px;

        strong {
          font-weight: bold;
          color: $color_black;
          font-size: 12px;
        }
      }
    }
  }

  .licence-usage {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 40px;

    .title {
      font-size: 11px;
      color: $color_grey;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.1em;
      white-space: nowrap;
      margin-right: 10px;
      flex: 1;
    }

    .delimiter {
      border-left: $border_grey_light_thin;
      height: 100%;
      margin: 0 30px;
    }

    .licence-usage-item {
      color: $color_grey_lighter;
      font-size: 12px;
      display: flex;
      align-items: center;

      .value {
        font-weight: 700;
        font-size: 16px;
        color: $color_black;
        text-transform: uppercase;
        margin-left: 15px;
      }
    }

    .loading-indicator {
      width: 28px;
    }
  }

  .box {
    .box-chart-title {
      display: flex;
      height: 28px;
      padding: 3px 3px 0 3px;
      align-items: center;
      position: relative;
      z-index: 2;

      h3 {
        font-size: 11px;
        letter-spacing: 0.05em;
        line-height: 14px;
        text-transform: uppercase;
        color: $color_grey;
        margin: 0;
        transition: font-size 0.2s ease-in-out;
      }

      .info-icon {
        font-size: 12px;
        color: $color_grey;
        margin-right: 5px;
        transition: margin-right 0.2s ease-in-out;
      }

      .chart-tooltip {
        max-width: 300px;
        font-size: 12px;
      }

      &.small {
        padding: 0;
        margin-bottom: 0;
        align-items: flex-start;

        h3 {
          font-size: 9px;
          line-height: 11px;
        }
      }

      &.sort-functionality,
      &.expand-functionality {
        justify-content: space-between;

        .union-elements {
          display: flex;
        }

        .control-buttons {
          flex-shrink: 0;
        }
      }

      .sort-button,
      .expand-button {
        background: $color_white;
        border-color: $color_border;
        border-radius: $br_medium;
        color: $color_grey_lighter;
        margin-left: 5px;

        svg {
          opacity: 1;
        }

        &:hover {
          background: $color_white;
          border-color: $color_grey;
          color: $color_black;
        }
      }
    }

    .value,
    .no-data {
      color: $color_black;
      font-size: 36px;
      text-align: center;
      letter-spacing: 0.5px;
      font-weight: 500;
      margin: 0;

      &.error-value {
        font-size: 13px;
        font-weight: bold;
        line-height: 135%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color_grey;
        width: 320px;
        box-sizing: border-box;
        height: calc(100% - 31px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;

        .chart-icon {
          font-size: 24px;
          display: block;
          margin-bottom: 7px;
        }

        &.vertical {
          padding-right: 10px;
        }
      }
    }

    .no-data {
      font-size: 15px;
    }

    &.loading {
      position: relative;

      &:before {
        margin: -13px 0 0 -13px;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        content: "";
        border-radius: 24px;
        background-clip: padding-box;
        border-color: rgba($color_primary, 0.25);
        border-width: 2px;
        border-style: solid;
        border-top-color: $color_primary;
        animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      }

      span {
        color: transparent;
      }
    }

    .horizontal-chart {
      height: calc(100% - 31px);
      position: relative;
      transition: all 0.2s ease-out;
    }

    &.collapsed-start {
      transition: all 0.2s ease-in;
      z-index: 4;
      position: fixed;
    }

    &.expanded {
      position: fixed;
      z-index: 11;
      transition: all 0.2s ease-out;

      .horizontal-chart {
        height: calc(100% - 45px);
        margin-top: 15px;
      }

      .vertical-chart {
        .legend {
          padding-top: 15px;

          .legend-button {
            font-size: 13px;
          }
        }
      }

      .box-chart-title {
        .info-icon {
          margin-right: 7px;
        }

        h3 {
          font-size: 16px;
          letter-spacing: 0.1em;
        }
      }
    }
  }

  .grid {
    display: grid;

    grid-template-columns: 32.75% 16.375% 16.375% 32.75%;
    grid-template-rows: 290px 290px 290px 290px 290px;
    justify-content: space-between;
    grid-row-gap: 10px;

    .events-over-time {
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
    }

    .identified-customers-over-time {
      grid-column: 3 / span 2;
      grid-row: 1 / span 1;
    }

    .customers-updated {
      grid-column: 1 / span 2;
      grid-row: 3 / span 1;
    }

    .customers-per-sources-count {
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
    }

    .customers-per-source {
      grid-column: 3 / span 2;
      grid-row: 2 / span 1;
    }

    .events-per-event-type {
      grid-column: 1 / span 1;
      grid-row: 4 / span 2;
    }

    .time-since-last-received-event {
      grid-column: 2 / span 2;
      grid-row: 4 / span 2;
    }

    .customers-per-attribute {
      grid-column: 4 / span 1;
      grid-row: 4 / span 2;
    }

    .suspicious-customer-entities {
      grid-column: 3 / span 2;
      grid-row: 3 / span 1;
    }

    .customers-per-attribute,
    .events-per-event-type,
    .time-since-last-received-event,
    .time-since-last-attribute-update {
      padding-right: 0;

      &.box {
        .box-chart-title {
          padding-right: 13px;
        }

        .vertical-chart {
          padding-right: 13px;
        }
      }
    }
  }
}

.expand-overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color_black, 0.5);
}
