@import "scssShared/variables.scss";

.tippy-box[data-theme~="error"][data-placement^="bottom"] {
  background-color: $color_warning_red;

  .tippy-content {
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    padding: 5px 10px;
  }

  .tippy-arrow {
    color: $color_warning_red;
  }
}
