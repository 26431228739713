@import "scssShared/variables.scss";

.insights {
  .tiles-section {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 8px;
    row-gap: 10px;
  }

  .insights-no-content {
    .info-message {
      margin: 20px 10px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      a {
        text-transform: none;
        font-size: 18px;
        font-weight: bold;
        color: $color_primary;
      }
    }
  }
}
