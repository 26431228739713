@import "scssShared/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 100%;

  & * {
    box-sizing: border-box;
  }
}

.main {
  position: relative;
}

.scrollContainer {
  overflow: auto;
  display: flex;
}

.leftOverlay,
.rightOverlay {
  &.hidden {
    display: none;
  }
}

.leftOverlay {
  // Relative to .headerCellWrapper
  position: absolute;
  width: 20px;
  pointer-events: none;
  left: calc(100% + 5px);
  top: 0;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
}

.rightOverlay {
  // Relative to .main
  position: absolute;
  width: 20px;
  pointer-events: none;
  right: 0;
  top: 0;
  height: calc(100% - 19px);
  background: linear-gradient(to right, transparent, white);
}

.table {
  flex: 1;
  display: grid;
  grid-template-columns: min-content;
  grid-auto-flow: column;
}

.tableHeader,
.tableHeaderInner,
.tableBody,
.column {
  display: contents;
}

.headerCell,
.bodyCell {
  padding: 0 10px;
  white-space: pre;
}

.headerCellWrapper,
.bodyCell {
  &:not(:last-child) .inner {
    border-bottom: $border_grey_white_bg;
  }
}

.inner {
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  text-align: initial;
}

.headerCellWrapper {
  position: sticky;
  left: 0;
  background-color: white;
  margin-right: 5px;

  &:first-child .headerCell {
    border-top: $border_grey_light_thin;
    border-radius: $br_small $br_small 0 0;
  }
  &:last-child .headerCell {
    border-bottom: $border_grey_light_thin;
    border-radius: 0 0 $br_small $br_small;
  }

  &::after {
    content: "";
    position: absolute;
    left: 100%;
    background-color: white;
    width: 5px;
    height: 100%;
    top: 0;
  }
}

.headerCell {
  border-left: $border_grey_light_thin;
  border-right: $border_grey_light_thin;
  background-color: white;
  color: $color_black_lighter;
  font-weight: 600;
  font-size: 12px;
  height: 100%;
}

.column {
  &:not(:last-child) .bodyCell {
    border-right: $border_grey_white_bg;
  }

  &:first-child .bodyCell {
    border-left: $border_grey_light_thin;
    &:first-child {
      border-top-left-radius: $br_small;
    }
    &:last-child {
      border-bottom-left-radius: $br_small;
    }
  }

  &:last-child .bodyCell {
    border-right: $border_grey_light_thin;
    &:first-child {
      border-top-right-radius: $br_small;
    }
    &:last-child {
      border-bottom-right-radius: $br_small;
    }
  }
}

.bodyCell {
  background-color: $color_grey_secondary_menu;
  font-size: 14px;
  color: $color_black_lighter;

  &:first-child {
    border-top: $border_grey_light_thin;
  }
  &:last-child {
    border-bottom: $border_grey_light_thin;
  }

  img {
    max-width: 60px;
    height: auto;
  }

  a {
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: inherit;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: $color_grey_lighter;
  font-weight: 600;
  font-size: 12px;
}

.pageNumber {
  // Match LoadingIndicator size
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
