@import "scssShared/variables.scss";

// it need wrapper with position:relative specified
.search-field-wraper {
  position: relative;
  width: 100%;

  .search-field {
    .search-button {
      position: absolute;
      top: 4px;
      right: 4px;
      border-radius: $br_small;
      box-shadow: none;
      font-size: 14px;
    }

    .clear-button {
      position: absolute;
      right: 56px;
      top: 13px;
    }

    input {
      border-radius: $br_small;
      padding: 16px 80px 16px 15px;
      width: 100%;
      box-sizing: border-box;
      box-shadow: $bs_smallest;
      font-size: 16px;
      line-height: 18px;
      margin: 0;

      &.full-width {
        width: 100%;
      }

      &:focus {
        + .search-button {
          opacity: 1;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.medium {
    .search-field {
      .search-button {
        width: 36px;
        height: 36px;
        top: 4px;
        right: 4px;
        padding: 4px;
      }

      .clear-button {
        top: 6px;
        right: 44px;
        padding: 8px 4px;
        min-width: 26px;
      }

      input {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 70px;
      }
    }
  }
}
