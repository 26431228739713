@import "scssShared/variables.scss";

.paper {
  background: $color_white;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: $br_medium;
  border: $border_grey_light_thin;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  justify-content: space-between;

  &.has-header {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.05) inset;
  }

  &.no-padding-top {
    padding-top: 0px;
  }

  &.no-padding {
    padding: 0;
  }
}
