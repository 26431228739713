@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.event-box {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 15px 0 15px;
  margin-left: 20px;
  margin-top: 10px;
  width: auto;
  color: $color_grey_lighter;
  position: relative;
  border-top: 3px solid $color_primary;

  &.bottom-pad {
    padding: 15px;
  }

  &.has-timeline {
    margin-left: 74px;
    width: calc(100% - 94px);
  }

  .event-source-icon {
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    position: absolute;
    padding: 2px;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_primary;

    img {
      max-width: 18px;
      max-height: 18px;
    }
  }

  .event-content {
    &.collapse {
      max-height: 110px;
      overflow: hidden;
      position: relative;
    }
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    align-items: center;

    &:only-child {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      border-top-color: $color;

      .event-source-icon {
        background: $color;
      }
    }
  }

  .datetime {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: $color_border;
  }

  h4 {
    font-size: 16px;
    margin: 0;
    color: $color_black;
    word-break: break-word;
    display: block;
    flex: 1;
  }

  .payload {
    .raw-payload {
      background-color: $color_catskill_white;
      border: $border_grey_light_thin;
      border-radius: $br_small;
      padding: 8px;
      font-size: 13px;
      font-weight: 400;
      color: $color_black_lighter;
      max-width: 100%;
      box-sizing: border-box;

      pre {
        margin: 0;
        white-space: pre-wrap;
        word-wrap: break-word;
        max-width: 100%;
      }
    }

    .payload-overlay {
      width: 100%;
      position: absolute;
      bottom: 0;
      pointer-events: none;

      &.collapse {
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 1) 100%
        );
      }

      &.big {
        height: 60%;
      }

      &.small {
        height: 30%;
      }
    }
  }

  .show-more-less-btn {
    @include flexAcross();
    justify-content: center;
    margin-bottom: 5px;

    &.expanded {
      margin-top: 5px;
    }

    .button {
      height: 20px;
    }
  }
}
