@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.header {
  display: flex;
  gap: 10px;
}

.page {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paper {
  display: flex;
  flex-direction: column;
  padding: 0;

  .row {
    display: flex;
    padding: 30px;

    &:nth-child(even) {
      background: $color_catskill-white;
      border-top: 1px solid $color_border;
      border-bottom: 1px solid $color_border;

      &:last-child {
        border-bottom-left-radius: $br_medium;
        border-bottom-right-radius: $br_medium;
        border-bottom: none;
      }
    }

    .description {
      box-sizing: border-box;
      flex-basis: 20%;
      display: flex;
      padding-right: 20px;

      h3 {
        color: $color_gray_800;
        font-size: 21px;
        letter-spacing: -0.025em;
        line-height: 25px;
        margin: 0;
      }

      p {
        color: $color_gray_500;
        font-size: 12px;
        line-height: 15px;
        margin: 0;
      }

      code {
        color: $color_gray_700;
        background-color: $color_gray_200;
        padding: 0 2px;
      }
    }

    .sendersWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: stretch;

      .label {
        font-size: 10px;
        font-weight: 700;
        color: $color_black;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    .sender {
      display: flex;
      align-items: center;
      gap: 20px;
      border-bottom: $border_grey_light_thin;
      padding: 10px 0;

      .defaultButton,
      .resendButton {
        font-size: 13px;
        text-transform: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
      }

      .senderEmail {
        flex: 1;
        font-weight: 700;
      }

      .default {
        color: $color_black_lighter;
      }

      .verified {
        color: $color_green;
      }

      .default,
      .verified {
        font-size: 13px;
        display: flex;
        gap: 5px;
        align-items: center;
      }

      .resendButton {
        & > span {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }

    .addSender {
      display: flex;
      gap: 20px;
      align-items: center;

      .addSenderEmail {
        flex: 1;
      }
    }

    .addSenderButton {
      align-self: flex-start;
    }

    .fields {
      flex-basis: 80%;
      display: flex;
      gap: 10px;

      .columnFlexBox {
        display: flex;
        flex-direction: column;
        flex-basis: 40%;

        .rowFlexBox {
          display: flex;

          .server {
            flex-basis: 80%;

            input {
              border-radius: 4px 0 0 4px;
            }

            &.error {
              input {
                @include input-error-animation;
              }
            }
          }

          .port {
            flex-basis: 20%;

            input {
              border-left: none;
              border-radius: 0 4px 4px 0;
            }

            &.error {
              input {
                @include input-error-animation;
                border-left: none;
              }
            }
          }
        }
      }

      .credentialsFlexBox {
        display: flex;
        flex-basis: 60%;
        gap: 10px;
      }

      .attributePickerFlexBox {
        width: 35%;
        display: flex;
        flex-direction: column;

        .label {
          @include input-label-styles;
        }
      }
    }
  }
}
