@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.aceEditorContainer {
  .aceEditorWrapperInner {
    position: relative;

    .expandButton {
      position: absolute;
      right: 4px;
      top: 4px;

      background: $color_white;
      border-color: $color_border;
      border-radius: $br_medium;
      color: $color_grey_lighter;

      svg {
        opacity: 1;
      }

      &:hover {
        background: $color_white;
        color: $color_black;
        border-color: $color_grey;
      }
    }
  }

  .textareaExpandModal {
    text-align: left;

    p {
      margin: 0 0 10px;
      line-height: 25px;
      font-size: 17px;

      &:last-of-type {
        margin: 0 0 20px;
      }
    }

    ul {
      margin: 5px 0 0;
    }

    .modalButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
}
