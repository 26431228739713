@import "scssShared/variables.scss";

.segments-list {
  .segments-search {
    width: 280px;
  }

  .paper {
    .tag-filter {
      justify-content: space-between;
      padding: 5px 5px 10px 5px;

      .tags-wrapper {
        display: flex;
        align-items: center;
        max-width: 55%;

        .label-tags {
          display: flex;
          align-items: center;

          .selected-tags {
            flex-shrink: 0;
          }

          .tags-and-picker {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }
        }

        .tag-picker {
          margin-right: 0;
        }
      }

      .segment-filters {
        display: flex;

        .segment-filters-label {
          font-size: 11px;
          letter-spacing: 1px;
          margin-right: 10px;
          line-height: 34px;
          color: $color_grey;
          text-transform: uppercase;
          font-weight: bold;
        }

        .segment-filter-button {
          margin-right: 5px;

          .button-icon {
            margin-right: 2px;
          }
        }
      }
    }
  }

  .segment-forbidden-tooltip {
    padding: 10px;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
  }

  .segment-name {
    font-size: 16px;
    font-weight: 700;
    color: $color_black;
    display: flex;
    align-items: center;
    padding: 10px 0;
    max-width: 100%;

    .segment-name-text {
      word-wrap: break-word;
      min-width: 0;
    }

    .scheduling-icon {
      width: 22px;
      height: 20px;
      color: $color_grey;
      border-radius: $br_small;
      border: 1px solid $color_border;
      box-sizing: border-box;
      background: #f9f9f9;
      margin-right: 12px;
      flex-shrink: 0;
      font-size: 11px;
      font-weight: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:not(.active) {
        &:after {
          content: "";
          width: 16px;
          height: 3px;
          box-sizing: border-box;
          border-top: 1px solid #f9f9f9;
          border-bottom: 1px solid #f9f9f9;
          position: absolute;
          background-color: $color_grey;
          transform: rotate(-45deg);
        }
      }

      &.active {
        background: rgba(254, 127, 102, 0.1);
        border-color: $color_primary;
        color: $color_primary;
      }
    }
  }

  .tags-cell {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .tag {
      cursor: pointer;
    }
  }

  .modified-by {
    font-size: 11px;
  }

  .disabled-segment > div {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.scheduling-tooltip .tippy-content {
  max-width: 260px;
  padding: 10px;
  box-sizing: border-box;

  .destination-schedule {
    background: $color_primary;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px;
    min-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        background: $color;
      }
    }

    img {
      width: 24px;
      height: auto;
      flex-shrink: 0;
      margin: 0 5px 0 0;
    }

    p {
      margin: 0;
      color: $color_white;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold;
    }
  }
}
