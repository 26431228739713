@import "scssShared/variables.scss";

.update-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .update-modal {
    width: 480px;
    background: linear-gradient(270deg, #fe7f66 0%, #ff5c3c 100%);
    border-radius: $br_small;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1001;
    padding: 20px;

    header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 32px;
        height: auto;
        margin-right: 5px;
      }

      h3 {
        font-size: 16px;
        font-weight: bold;
        color: $color_white;
        margin: 0;
      }
    }

    section {
      .text {
        font-size: 14px;
        color: $color_white;
        line-height: 135%;

        p {
          margin: 0;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .later,
        .update {
          font-weight: bold;
        }

        .later {
          color: white;
        }

        .update {
          background: $color_white;
          border: none;
          color: $color_primary;
        }

        .update-icon {
          font-size: 12px;
        }
      }
    }
  }

  .update-modal-overlay {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color_white, 0.8);
  }
}
