@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.search {
  width: 300px;
}

.paper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.name {
  word-break: break-word;
}

.date {
  width: 150px;
}

.actions {
  width: 100px;

  :not(:first-child) {
    margin-left: 5px;
  }
}

.modifiedBy {
  font-size: 11px;
}

.primary {
  font-weight: bold;
  color: $color_primary;
}

.boltIcon,
.warningIcon {
  margin-right: 5px;
}

.boltIcon {
  color: $color_primary;
}

.warningIcon {
  color: #f9b549;
}
